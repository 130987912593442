import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { Appointment, AppointmentAdapter, Conflict, ConflictAdapter, HttpParamsUtils } from '../models';

@Injectable({ providedIn: 'root' })
export class AppointmentsService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: AppointmentAdapter,
    private conflictAdapter: ConflictAdapter
  ) {}

  private getAppointments(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getAppointmentsWithPagination(
    pagination: PaginationInterface,
    search?: SearchInterface
  ): Observable<PaginationResults<Appointment>> {
    return this.getAppointments(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const appointments: Appointment[] = [];

        if (results.results) {
          for (const i of results.results) {
            appointments.push(this.adapter.adaptToObject(i));
          }
          results.results = appointments;

          return results as PaginationResults<Appointment>;
        }
      })
    );
  }

  public getAppointmentsWithoutPagination(search?: SearchInterface): Observable<Appointment[]> {
    return this.getAppointments(undefined, search).pipe(
      map((results: any[]) => {
        const appointments: Appointment[] = [];

        results.forEach((elem) => {
          appointments.push(this.adapter.adaptToObject(elem));
        });
        return appointments;
      })
    );
  }

  public getAppointment(id: number): Observable<Appointment> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/${id}/`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public addAppointment(appointment: Appointment, force?: boolean): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/`;
    const obj = this.adapter.adaptToRequest(appointment);
    let p = new HttpParams();

    if (force) {
      p = p.append('force', 'true');
    }

    return this.httpClient.post(url, obj, { params: p }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 400 && error.error && error.error.conflicts) {
          const conflicts: Conflict[] = [];

          error.error.conflicts.forEach((conflict: any) => {
            conflicts.push(this.conflictAdapter.adaptToObject(conflict));
          });

          return throwError(conflicts);
        } else {
          return throwError(error);
        }
      }),
      map((results: any) => {
        return results;
      })
    );
  }

  public updateBulkAppointment(bulk: any, force?: boolean): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/bulk-edit/`;
    let p = new HttpParams();

    if (force) {
      p = p.append('force', 'true');
    }

    if (bulk.notification && bulk.notification === true) {
      p = p.append('send_notification', true);
    } 

    return this.httpClient.post(url, bulk, { params: p }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 400 && error.error && error.error.conflicts) {
          const conflicts: Conflict[] = [];

          error.error.conflicts.forEach((conflict: any) => {
            conflicts.push(this.conflictAdapter.adaptToObject(conflict));
          });

          return throwError(conflicts);
        } else {
          return throwError(error);
        }
      }),
      map((results: any) => {
        return results;
      })
    );
  }

  public updateAppointment(appointment: Appointment, force?: boolean): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/${appointment.id}/`;
    const obj = this.adapter.adaptToRequest(appointment);
    let p = new HttpParams();

    if (force) {
      p = p.append('force', 'true');
    }

    return this.httpClient.put(url, obj, { params: p }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 400 && error.error && error.error.conflicts) {
          const conflicts: Conflict[] = [];

          error.error.conflicts.forEach((conflict: any) => {
            conflicts.push(this.conflictAdapter.adaptToObject(conflict));
          });

          return throwError(conflicts);
        } else {
          return throwError(error);
        }
      }),
      map((results: any) => {
        return results;
      })
    );
  }

  public deleteAppointment(appointment: Appointment): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/${appointment.id}/`;

    return this.httpClient.request('delete', url, { body: { modified_at: appointment.modifiedAtOriginal } }).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  public deleteBulkAppointment(bulk: any): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/bulk-delete/`;
    let params: HttpParams = new HttpParams();

    if (bulk.notification && bulk.notification === true) {
      params = params.append('send_notification', true);
    } 

    return this.httpClient.request('delete', url, { body: bulk, params: params }).pipe(
      map((results: any) => {
        return results;
      })
    );
  }

  public clearAllAppointmentPublicRegistrations(appointment: Appointment): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `appointments/${appointment.id}/clear_registrations/`;
    return this.httpClient.put(url, {}).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
}
