import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { PaginationParam } from 'src/app/data/interfaces';
import { Office, Room, Search } from 'src/app/data/models';
import { RoomsService } from 'src/app/data/services';

@Component({
  selector: 'app-room-select',
  templateUrl: './room-select.component.html',
  styleUrls: ['./room-select.component.scss'],
})
export class RoomSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FormControl;
  @Input() office: Office | string;
  @Input() params: PaginationParam[] = [];

  private subscriber = new Subject();

  public rooms: Room[];

  constructor(private roomsService: RoomsService) {}

  ngOnInit() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof Room) {
            return value as Room;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        switchMap((filter: string | Room) => {
          const search = new Search('', this.params);

          if (this.office && this.office instanceof Office && this.office.id > 0) {
            search.params.push({
              name: 'office',
              value: this.office.id,
            } as PaginationParam);
          }
          if (filter instanceof Room) {
            return of(filter);
          } else {
            search.value = filter ? filter : '';
            return this.roomsService.getRoomsWithoutPagination(search).pipe(takeUntil(this.subscriber));
          }
        })
      )
      .subscribe((value: Room[] | Room) => {
        if (!(value instanceof Room)) {
          this.rooms = value;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.office && changes.office.currentValue !== changes.office.previousValue) {
      this.rooms = [];
    }
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayRoom(room: Room): string {
    return room && room.name ? room.name : '';
  }
}
