import { FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { Office, Room, Therapy } from 'src/app/data/models';

export function oneDimensionRequiredValidator(): ValidatorFn {
  const validator: ValidatorFn = (group: FormGroup) => {

    const therapyValue = group.get('therapy').value;
    const officeValue = group.get('office').value;
    const roomValue = group.get('room').value;
    const usersValue = (group.get('users') as FormArray).length;

    return therapyValue instanceof Therapy ||
      officeValue instanceof Office ||
      roomValue instanceof Room ||
      usersValue > 0 ? null : { oneDimensionRequired: true };
  };

  return validator;
}
