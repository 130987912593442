<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *transloco="let t">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
      (change)="comparableLeafItemSelectionToggle(node)" color="primary">{{getNameFromItem(node.item)}}</mat-checkbox>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button [attr.aria-label]="'toggle ' + node.filename" matTreeNodeToggle>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
      (change)="comparableItemSelectionToggle(node)" color="primary">
      {{ node.level <= 0 ? (t(getNameFromItem(node.item).trim()) | capitalize) : getNameFromItem(node.item)}}</mat-checkbox>
    <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
  </mat-tree-node>
</mat-tree>
