import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { PaginationParam } from 'src/app/data/interfaces';
import { Goal, Search } from 'src/app/data/models';
import { GoalsService } from 'src/app/data/services';

@Component({
  selector: 'app-goal-select',
  templateUrl: './goal-select.component.html',
  styleUrls: ['./goal-select.component.scss'],
})
export class GoalSelectComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() params: PaginationParam[] = [];

  private subscriber = new Subject();

  public goals: Goal[];

  constructor(private goalsService: GoalsService) {}

  ngOnInit() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof Goal) {
            return value as Goal;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        switchMap((filter: string | Goal) => {
          if (filter instanceof Goal) {
            return of(filter);
          } else {
            const search = new Search(filter ? filter : '', this.params);
            return this.goalsService.getGoalsWithoutPagination(search).pipe(takeUntil(this.subscriber));
          }
        })
      )
      .subscribe((value: Goal[] | Goal) => {
        if (!(value instanceof Goal)) {
          this.goals = value;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayGoal(goal: Goal): string {
    return goal && goal.name ? goal.name : '';
  }
}
