import { FormGroup, ValidatorFn } from '@angular/forms';

export function passwordEqualsValidator(): ValidatorFn {
  const validator: ValidatorFn = (group: FormGroup) => {

    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { notEqual: true };
  };

  return validator;
}
