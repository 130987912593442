import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { BusinessGroup, BusinessGroupAdapter, HttpParamsUtils } from '../models';

@Injectable({ providedIn: 'root' })
export class BusinessGroupsService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: BusinessGroupAdapter
  ) {}

  private getBusinessGroups(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `business-groups/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getBusinessGroupsWithPagination(
    pagination: PaginationInterface,
    search?: SearchInterface
  ): Observable<PaginationResults<BusinessGroup>> {
    return this.getBusinessGroups(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const businessGroups: BusinessGroup[] = [];

        if (results.results) {
          for (const i of results.results) {
            businessGroups.push(this.adapter.adaptToObject(i));
          }
          results.results = businessGroups;

          return results as PaginationResults<BusinessGroup>;
        }
      })
    );
  }

  public getBusinessGroupsWithoutPagination(search?: SearchInterface): Observable<BusinessGroup[]> {
    return this.getBusinessGroups(undefined, search).pipe(
      map((results: any[]) => {
        const businessGroups: BusinessGroup[] = [];

        results.forEach((elem) => {
          businessGroups.push(this.adapter.adaptToObject(elem));
        });

        return businessGroups;
      })
    );
  }

  public getBusinessGroup(id: number): Observable<BusinessGroup> {
    const url = this.settingsService.getSettings().baseUrl + `business-groups/${id}/`;

    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public createBusinessGroup(group: BusinessGroup): Observable<BusinessGroup> {
    const url = this.settingsService.getSettings().baseUrl + `business-groups/`;

    return this.httpClient.post(url, this.adapter.adaptToRequest(group)).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public updateBusinessGroup(group: BusinessGroup): Observable<BusinessGroup> {
    const url = this.settingsService.getSettings().baseUrl + `business-groups/${group.id}/`;

    return this.httpClient.put(url, this.adapter.adaptToRequest(group)).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public removeBusinessGroup(id: number): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `business-groups/${id}/`;

    return this.httpClient.delete(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
