import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { HttpParamsUtils, TherapyBlock, TherapyBlockAdapter } from '../models';
import { Conflict, ConflictAdapter } from '../models/conflict';

@Injectable({ providedIn: 'root' })
export class TherapyBlocksService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: TherapyBlockAdapter,
    private conflictAdapter: ConflictAdapter
  ) { }

  public getTherapyBlocks(pagination: PaginationInterface, search?: SearchInterface): Observable<PaginationResults<TherapyBlock>> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams }).pipe(
      map((results: PaginationResults<TherapyBlock>) => {

        const therapyBlocks: TherapyBlock[] = [];
        for (const i of results.results) {
          therapyBlocks.push(this.adapter.adaptToObject(i));
        }
        results.results = therapyBlocks;

        return results as PaginationResults<TherapyBlock>;
      })
    );
  }

  public getTherapyBlock(id: number | string, pagination?: PaginationInterface): Observable<TherapyBlock> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/${id}/`;
    const parameters = pagination && pagination.toHttpParams();

    return this.httpClient.get(url, { params: parameters }).pipe(
      map((result: any) => {
        return this.adapter.adaptToObject(result);
      })
    );
  }

  public addTherapyBlock(therapyBloc: TherapyBlock): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/`;
    const obj = this.adapter.adaptToRequest(therapyBloc);

    return this.httpClient.post(url, obj, {}).pipe(
      map((results: any) => {
        return results;
      })
    );
  }

  public updateTherapyBlock(therapyBloc: TherapyBlock, force?: boolean): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/${therapyBloc.id}/`;
    const obj = this.adapter.adaptToRequest(therapyBloc);
    let p = new HttpParams();

    if (force) {
      p = p.append('force', 'true');
    }

    return this.httpClient.put(url, obj, { params: p }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 400 && error.error && error.error.conflicts) {
          const conflicts: Conflict[] = [];

          error.error.conflicts.forEach((conflict: any) => {
            conflicts.push(this.conflictAdapter.adaptToObject(conflict));
          });

          return throwError(conflicts);
        } else {
          return throwError(error);
        }
      }),
      map((results: any) => {
        return results;
      })
    );
  }

  public deleteTherapyBlock(therapyBloc: TherapyBlock): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/${therapyBloc.id}/`;

    return this.httpClient.delete(url, {}).pipe(
      map((results: any) => {
        return results;
      })
    );
  }

  public applyTherapyBlock(therapyBloc: TherapyBlock, force?: boolean): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/${therapyBloc.id}/generate-appointments/`;
    let p = new HttpParams();

    if (force) {
      p = p.append('force', 'true');
    }
    return this.httpClient.post(url, {}, { params: p }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 400 && error.error && error.error.conflicts) {
          const conflicts: Conflict[] = [];

          error.error.conflicts.forEach((conflict: any) => {
            conflicts.push(this.conflictAdapter.adaptToObject(conflict));
          });

          return throwError(conflicts);
        } else {
          return throwError(error);
        }
      }),
      map((results: any) => {
        return results;
      })
    );
  }

  public duplicateTherapyBlock(therapyBloc: TherapyBlock): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `therapy-blocks/${therapyBloc.id}/duplicate/`;
    return this.httpClient.post(url, {}).pipe(
      map((results: any) => {
        return results;
      })
    );
  }
}
