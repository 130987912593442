import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { AppointmentVisibility, Goal, GoalAdapter } from '.';
import { Adapter, HttpEntityInterface } from '../interfaces';
import { Block, BlockAdapter } from './block';
import { Office, OfficeAdapter } from './office';
import { Room, RoomAdapter } from './room';
import { Therapy, TherapyAdapter } from './therapy';
import { User, UserAdapter } from './user';

export class TherapyBlock implements HttpEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public startDate: moment.Moment,
    public endDate: moment.Moment,
    public visibility: AppointmentVisibility,
    public markOccupied: boolean,
    public name?: string,
    public therapy?: Therapy,
    public office?: Office,
    public room?: Room,
    public goal?: Goal,

    public createdAt?: moment.Moment,
    public modifiedAt?: moment.Moment,

    public users?: User[],
    public blocks?: Block[],
    public createdBy?: User,
    public modifiedBy?: User,
    public deletedAt?: moment.Moment,
    public deletedBy?: User,
    public title?: string,
    public color?: string
  ) {}

  public isBlockTherapyAlreadyApplied(): boolean {
    let total = 0;

    if (this.blocks) {
      this.blocks.forEach((block: Block) => {
        total += block && block.nbAppointments ? block.nbAppointments : 0;
      });
    }

    return total > 0;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TherapyBlockAdapter implements Adapter<TherapyBlock> {
  constructor(
    private userAdapter: UserAdapter,
    private officeAdapter: OfficeAdapter,
    private roomAdapter: RoomAdapter,
    private therapyAdapter: TherapyAdapter,
    private blockAdapter: BlockAdapter,
    private goalAdapter: GoalAdapter,
  ) {}

  public adaptToObject(item: any): TherapyBlock {
    const therapy = item.therapy && this.therapyAdapter.adaptToObject(item.therapy);
    const room = item.room && this.roomAdapter.adaptToObject(item.room);
    const office = item.office && this.officeAdapter.adaptToObject(item.office);
    const goal = item.goal && this.goalAdapter.adaptToObject(item.goal);

    const therapyBlock = new TherapyBlock(
      item.id,
      item.url,
      moment.tz(item.start_date, 'UTC'),
      moment.tz(item.end_date, 'UTC'),
      item.visibility as AppointmentVisibility,
      item.mark_occupied,
      item && item.name,
      therapy,
      office,
      room,
      goal,
      moment.tz(item.created_at, 'UTC'),
      moment.tz(item.modified_at, 'UTC')
    );

    therapyBlock.title = item && item.title;
    therapyBlock.color = item && item.color;
    therapyBlock.deletedAt = item && moment.tz(item.deleted_at, 'UTC');

    if (item && item.created_by) {
      therapyBlock.createdBy = this.userAdapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      therapyBlock.modifiedBy = this.userAdapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      therapyBlock.deletedBy = this.userAdapter.adaptToObject(item.deleted_by);
    }

    if (item && item.users) {
      const users: User[] = [];
      item.users.forEach((element: any) => users.push(this.userAdapter.adaptToObject(element)));
      therapyBlock.users = users;
    }

    if (item && item.blocks) {
      const blocks: Block[] = [];
      item.blocks.forEach((element: any) => blocks.push(this.blockAdapter.adaptToObject(element)));
      therapyBlock.blocks = blocks;
    }

    return therapyBlock;
  }
  public adaptToRequest(item: TherapyBlock): any {
    const object = {
      start_date: item.startDate.format('YYYY-MM-DD'),
      end_date: item.endDate.format('YYYY-MM-DD'),
      visibility: item.visibility.toString(),
      mark_occupied: item.markOccupied,
      name: null,
      therapy: null,
      office: null,
      room: null,
      goal: null,
      blocks: [],
      users: [],
    };

    if (item.id) {
      object[`id`] = item.id;
    }
    if (item.title) {
      object[`title`] = item.title;
    }
    if (item.color) {
      object[`color`] = item.color;
    }
    if (item.name) {
      object[`name`] = item.name;
    }
    if (item.therapy) {
      object[`therapy`] = item.therapy.id;
    }
    if (item.office) {
      object[`office`] = item.office.id;
    }
    if (item.room) {
      object[`room`] = item.room.id;
    }
    if (item.goal) {
      object[`goal`] = item.goal.id;
    }

    item.blocks.forEach((block) => {
      object[`blocks`].push(this.blockAdapter.adaptToRequest(block));
    });

    item.users.forEach((user: User) => {
      object[`users`].push(user.id);
    });

    return object;
  }
}
