<div class="row no-gutters align-items-start justify-content-between" *transloco="let t">
  <div class="col pl-0">
    <app-office-select [control]="control.controls.office"></app-office-select>
    <mat-chip-list>
      <mat-chip *ngFor="let office of selectedFilters.offices; trackBy:trackByModelId" [removable]="true"
        (removed)="removeOfficeFromFilters(office)">{{office.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <span *ngIf="selectedFilters.offices.length === 0" class="font-weight-bolder">
      {{t('all') | capitalize}}
    </span>
  </div>
  <div class="col">
    <app-room-select [control]="control.controls.room"></app-room-select>
    <mat-chip-list>
      <mat-chip *ngFor="let room of selectedFilters.rooms; trackBy:trackByModelId" [removable]="true"
        (removed)="removeRoomFromFilters(room)">{{room.name}} ({{room.office ? room.office.name : '-'}})
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <span *ngIf="selectedFilters.rooms.length === 0" class="font-weight-bolder">
      {{t('all') | capitalize}}
    </span>
  </div>
  <div class="col">
    <app-therapy-select [control]="control.controls.therapy"></app-therapy-select>
    <mat-chip-list>
      <mat-chip *ngFor="let therapy of selectedFilters.therapies; trackBy:trackByModelId" [removable]="true"
        (removed)="removeTherapyFromFilters(therapy)">{{therapy.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <span *ngIf="selectedFilters.therapies.length === 0" class="font-weight-bolder">
      {{t('all') | capitalize}}
    </span>
  </div>
  <div class="col">
    <app-goal-select [control]="control.controls.goal"></app-goal-select>
    <mat-chip-list>
      <mat-chip *ngFor="let goal of selectedFilters.goals; trackBy:trackByModelId" [removable]="true"
        (removed)="removeGoalFromFilters(goal)">{{goal.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <span *ngIf="selectedFilters.goals.length === 0" class="font-weight-bolder">
      {{t('all') | capitalize}}
    </span>
  </div>
  <div class="col">
    <app-user-select [control]="control.controls.user"></app-user-select>
    <mat-chip-list>
      <mat-chip *ngFor="let user of selectedFilters.users; trackBy:trackByModelId" [removable]="true"
        (removed)="removeUserFromFilters(user)">{{user.getDisplayName()}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <span *ngIf="selectedFilters.users.length === 0" class="font-weight-bolder">
      {{t('all') | capitalize}}
    </span>
  </div>
  <div class="col pr-0">
    <app-patient-select [control]="control.controls.patient"></app-patient-select>
    <mat-chip-list>
      <mat-chip *ngFor="let patient of selectedFilters.patients; trackBy:trackByModelId" [removable]="true"
        (removed)="removePatientFromFilters(patient)">{{patient.getDisplayName()}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <span *ngIf="selectedFilters.patients.length === 0" class="font-weight-bolder">
      {{t('all') | capitalize}}
    </span>
  </div>
</div>