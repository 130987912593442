import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from 'src/app/data/models';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private oauthService: OAuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuthenticated();
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuthenticated();
  }
  isAuthenticated(): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated().pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return this.authService.getUser().pipe(
            catchError(() => {
              this.oauthService.logOut();
              return of(false);
            }),
            map((value: User | boolean) => value !== false)
          );
        } else {
          return of(isAuthenticated);
        }
      }),
      map((auth: boolean) => {
        if (!auth) {
          return this.router.parseUrl('/auth/login');
        }
        return auth;
      })
    );
  }
}
