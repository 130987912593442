import { TranslocoService } from '@ngneat/transloco';

export class DynamicLocaleId extends String {
  constructor(private translocoService: TranslocoService) {
    super('');
  }

  toString() {
    const language = this.translocoService.getActiveLang();

    switch (language) {
      case 'fr':
        return 'fr-CH';
      case 'de':
        return 'de-CH';
      default:
        return 'en-US';
    }
  }
}
