<div class="d-flex flex-row w-100 h-100 align-items-center justify-content-center">
  <div class="mr-4">
    <img
      [src]="logoUrl"
      onerror="this.src = 'assets/images/logo.svg'"
      alt="logo"
      srcset=""
      style="width: 17rem; height: auto"
      class="p-4"
    />
  </div>
  <router-outlet></router-outlet>
</div>
