/**
 * Full swiss date format
 *  - `dd.mm.yyyy`
 *  - `d.mm.yy`
 */
const swissDateDayMonthYear = /^(\d{1,2})\.(\d{2})\.(\d{2,4})$/;
/**
 * Partial swiss date format
 *  - `mm.yyyy`
 *  - `m.yy`
 *  - `dd.mm`
 *  - `d.mm`
 */
const swissDatePartial = /^(\d{1,2})\.(\d{2,4})$/;

export function convert(input: string): string {
  if (swissDateDayMonthYear.test(input)) {
    return input.replace(swissDateDayMonthYear, '$3-$2-$1');
  }
  if (swissDatePartial.test(input)) {
    return input.replace(swissDatePartial, '$2-$1');
  }
  return input;
}
