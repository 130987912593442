import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter } from '../interfaces';
import { Patient } from './patient';
import { User, UserAdapter } from './user';

export enum PatientAppointmentStateEnum {
  PENDING = 'PENDING',
  REGISTERED = 'REGISTERED',
  WAITING = 'WAITING',
  CANCELED = 'CANCELED',
  NO_SHOW = 'NO_SHOW',
  DONE = 'DONE',
  TO_PLAN = 'TO_PLAN',
  STATELESS = 'STATELESS'
}

export interface AppointmentState {
  id?: number;
  createdAt?: moment.Moment;
  modifiedAt?: moment.Moment;
  deletedAt?: moment.Moment;

  createdBy?: User;
  modifiedBy?: User;
  deletedBy?: User;
}

export interface UserAppointmentState extends AppointmentState {
  state?: string;
}

export interface PatientAppointmentState extends AppointmentState {
  state: PatientAppointmentStateEnum;
}

export class AppointmentStateAdapter implements Adapter<AppointmentState> {
  constructor(
    protected adapter: UserAdapter
  ) { }

  public adaptToObject(item: any): AppointmentState {
    const state: AppointmentState = {};

    state.id = item && item.id;
    state.createdAt = item && moment.tz(item.created_at, 'UTC');
    state.modifiedAt = item && moment.tz(item.modified_at, 'UTC');
    state.deletedAt = item && moment.tz(item.deleted_at, 'UTC');

    if (item && item.created_by) {
      state.createdBy = this.adapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      state.modifiedBy = this.adapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      state.deletedBy = this.adapter.adaptToObject(item.deleted_by);
    }

    return state;
  }
  public adaptToRequest(item: AppointmentState): any {
    const object = {};

    if (item && item.id) { object[`id`] = item.id; }

    return object;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAppointmentStateAdapter extends AppointmentStateAdapter {
  constructor(
    protected adapter: UserAdapter
  ) {
    super(adapter);
  }

  public adaptToObject(item: any): UserAppointmentState {
    return super.adaptToObject(item) as UserAppointmentState;
  }
  public adaptToRequest(user: User): any {
    const state = super.adaptToRequest(user.state);
    if (user) { state[`collaborator`] = user.id; }

    return state;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PatientAppointmentStateAdapter extends AppointmentStateAdapter {
  constructor(
    protected adapter: UserAdapter
  ) {
    super(adapter);
  }

  public adaptToObject(item: any): PatientAppointmentState {
    const state = super.adaptToObject(item) as PatientAppointmentState;
    state.state = item && item.state as PatientAppointmentStateEnum;

    return state;

  }
  public adaptToRequest(patient: Patient): any {
    const state = super.adaptToRequest(patient.state);
    if (patient.state && patient.state.state) { state[`state`] = patient.state.state; }
    if (patient) { state[`patient`] = patient.id; }

    return state;
  }
}
