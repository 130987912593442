import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export abstract class SnackbarConfigs {
  public static readonly defaultConfig = {
    panelClass: 'defaults',
    duration: 5000,
  };
  public static readonly errorConfig = {
    panelClass: 'error',
    duration: 15000,
  };
  public static readonly successConfig = {
    panelClass: 'success',
    duration: 7000,
  };
}

export interface SnackbarNotificationDataInterface {
  message: string;
  args?: any[];
}

@Component({
  selector: 'app-snackbar-notification',
  templateUrl: 'snack-notification.component.html',
  styleUrls: ['./snack-notification.component.scss'],
})
export class SnackbarNotificationComponent {
  public message: string;
  public args: any[];

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarNotificationDataInterface,
    public snackBarRef: MatSnackBarRef<SnackbarNotificationComponent>
  ) {
    if (this.data.message !== null && this.data.message !== undefined) {
      this.message = this.data.message;
    }

    if (this.data.args !== null && this.data.args !== undefined) {
      this.args = this.data.args;
    }
  }
}
