import { Injectable } from '@angular/core';
import { Adapter, HttpEntityInterface } from '../interfaces';
import { User, UserAdapter } from './user';

export class BusinessGroup implements HttpEntityInterface {
  constructor(public id: number, public url: string, public name: string, public users?: User[]) {}
}

@Injectable({
  providedIn: 'root',
})
export class BusinessGroupAdapter implements Adapter<BusinessGroup> {
  constructor(private userAdapter: UserAdapter) {}

  public adaptToObject(item: any): BusinessGroup {
    const businessGroup = new BusinessGroup(item.id, item.url, item.name, []);

    if (item.users && (item.users as any[]).length > 0) {
      businessGroup.users = (item.users as any[]).map((u) => this.userAdapter.adaptToObject(u));
    }

    return businessGroup;
  }
  public adaptToRequest(item: BusinessGroup): any {
    const group = {
      name: item.name,
      users: item.users ? item.users.map((u) => u.id) : [],
    };

    if (item.id) {
      group[`id`] = item.id;
    }

    return group;
  }
}
