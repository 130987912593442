<mat-form-field class="d-flex flex-column" *transloco="let t">
  <mat-label>{{ t('goal') | capitalize }}</mat-label>
  <input matInput type="text" [formControl]="control" [matAutocomplete]="auto" />
  <mat-error *ngIf="control.invalid">{{ t('validation.value-required') | capitalize }}</mat-error>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayGoal">
    <mat-option *ngFor="let option of goals" [value]="option">
      <span [ngClass]="{ 'font-italic font-weight-lighter': !option.active }">{{ option.name }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
