import { Injectable } from '@angular/core';
import { Adapter, HttpEntityInterface, Permission } from '../interfaces';

export class Group implements HttpEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public name: string,
    public permissions?: Permission[]
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class GroupAdapter implements Adapter<Group> {
  constructor() { }

  public adaptToObject(item: any): Group {
    const group = new Group(
      item.id,
      item.url,
      item.name
    );

    group.permissions = item && item.permissions ? item.permissions as Permission[] : [];

    return group;
  }
  public adaptToRequest(item: Group): any {
    return item.id;
  }
}
