// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  settings_url: '/settings/settings.json',
  translations_url: '/assets/i18n/',
  sentry: {
    url: 'https://10531c7e8ed24bc8bbd6737f56b18f51@sentry.vnv.ch/42',
    environment: 'local',
  },
  refreshInterval: 5 * 60 * 1000, // 5 minutes
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
