import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { PaginationParam } from 'src/app/data/interfaces';
import { Search, Therapy } from 'src/app/data/models';
import { TherapiesService } from 'src/app/data/services';
import { CapitalizePipe } from '../../pipes';

@Component({
  selector: 'app-therapy-select-bulk',
  templateUrl: './therapy-select-bulk.component.html',
  styleUrls: ['./therapy-select-bulk.component.scss'],
})
export class TherapySelectBulkComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() params: PaginationParam[] = [];

  private subscriber = new Subject();

  public therapies: Therapy[];

  public defaultTherapy: Therapy;

  constructor(
    private therapiesService: TherapiesService,
    private capitalizePipe: CapitalizePipe,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.translocoService.selectTranslate('actual-value').subscribe((value: string) => {
      this.defaultTherapy = new Therapy(
        -1,
        undefined,
        this.capitalizePipe.transform(value),
        undefined,
        undefined,
        undefined,
        undefined
      );
      this.defaultTherapy.active = true;
    });

    this.control.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof Therapy) {
            return value as Therapy;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        switchMap((filter: string | Therapy) => {
          if (filter instanceof Therapy) {
            return of(filter);
          } else {
            const search = new Search(filter ? filter : '', this.params);
            return this.therapiesService.getTherapiesWithoutPagination(search).pipe(takeUntil(this.subscriber));
          }
        })
      )
      .subscribe((value: Therapy[] | Therapy) => {
        if (!(value instanceof Therapy)) {
          this.therapies = value;
          this.therapies.splice(0, 0, this.defaultTherapy);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayTherapy(therapy: Therapy): string {
    return therapy && therapy.name ? therapy.name : '';
  }

  public onfocusIn(): void {
    if (this.control.value instanceof Therapy && this.control.value.id < 0 && this.control.untouched) {
      this.control.setValue('');
    }
  }
}
