import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { Goal, GoalAdapter, HttpParamsUtils } from '../models';

@Injectable({ providedIn: 'root' })
export class GoalsService {
  constructor(private httpClient: HttpClient, private settingsService: SettingsService, private adapter: GoalAdapter) {}

  private getGoals(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `goals/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getGoalsWithPagination(
    pagination: PaginationInterface,
    search?: SearchInterface
  ): Observable<PaginationResults<Goal>> {
    return this.getGoals(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const goals: Goal[] = [];

        if (results.results) {
          for (const i of results.results) {
            goals.push(this.adapter.adaptToObject(i));
          }
          results.results = goals;

          return results as PaginationResults<Goal>;
        }
      })
    );
  }

  public getGoalsWithoutPagination(search?: SearchInterface): Observable<Goal[]> {
    return this.getGoals(undefined, search).pipe(
      map((results: any[]) => {
        const goals: Goal[] = [];

        results.forEach((elem) => {
          goals.push(this.adapter.adaptToObject(elem));
        });
        return goals;
      })
    );
  }

  public getGoal(id: number | string): Observable<Goal> {
    const url = this.settingsService.getSettings().baseUrl + `goals/${id}/`;

    return this.httpClient.get(url, {}).pipe(
      map((result: any) => {
        return this.adapter.adaptToObject(result);
      })
    );
  }

  public updateGoal(goal: Goal): Observable<Goal> {
    const url = this.settingsService.getSettings().baseUrl + `goals/${goal.id}/`;

    return this.httpClient.put(url, this.adapter.adaptToRequest(goal)).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }
}
