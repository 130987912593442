import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Adapter, HttpEntityInterface } from '../interfaces';
import { PatientAppointmentStateAdapter, UserAppointmentStateAdapter } from './appointment-state';
import { Block, BlockAdapter } from './block';
import { Goal, GoalAdapter } from './goal';
import { Office, OfficeAdapter } from './office';
import { Patient, PatientAdapter } from './patient';
import { Room, RoomAdapter } from './room';
import { Therapy, TherapyAdapter } from './therapy';
import { User, UserAdapter } from './user';

export enum AppointmentVisibility {
  PUBLIC = 'PUBLIC',
  INTERN = 'INTERN',
  PRIVATE = 'PRIVATE',
}

export interface AppointmentRegistration {
  id: number;
  birthdate: Moment;
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
}

export class Appointment implements HttpEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public startDate: Moment,
    public endDate: Moment,
    public visibility: AppointmentVisibility,
    public markOccupied: boolean,

    public createdAt: Moment,
    public modifiedAt: Moment,

    public description?: string,
    public comment?: string,
    public address?: string,
    public color?: string,

    public therapy?: Therapy,
    public office?: Office,
    public room?: Room,
    public goal?: Goal,
    public users?: User[],
    public patients?: Patient[],
    public block?: Block,
    public registrations?: AppointmentRegistration[],

    public createdBy?: User,
    public modifiedBy?: User,
    public deletedAt?: Moment,
    public deletedBy?: User,

    public modifiedAtOriginal?: string,
    public title?: string,
    public flagAction?: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentAdapter implements Adapter<Appointment> {
  constructor(
    private userAdapter: UserAdapter,
    private officeAdapter: OfficeAdapter,
    private roomAdapter: RoomAdapter,
    private therapyAdapter: TherapyAdapter,
    private blockAdapter: BlockAdapter,
    private patientAdapter: PatientAdapter,
    private goalAdapter: GoalAdapter,
    private patientStateAdapter: PatientAppointmentStateAdapter,
    private userStateAdapter: UserAppointmentStateAdapter
  ) {}

  public adaptToObject(item: any): Appointment {
    const appointment = new Appointment(
      item.id,
      item.url,
      moment(item.date + ' ' + item.start_time),
      moment(item.date + ' ' + item.end_time),
      item.visibility as AppointmentVisibility,
      item.mark_occupied,

      moment(item.created_at),
      moment(item.modified_at),

      item.description,
      item.comment,
      item.address,
      item.color
    );

    appointment.title = item && item.title;
    appointment.flagAction = item && item.flag_action;
    appointment.therapy = item.therapy && this.therapyAdapter.adaptToObject(item.therapy);
    appointment.office = item.office && this.officeAdapter.adaptToObject(item.office);
    appointment.room = item.room && this.roomAdapter.adaptToObject(item.room);
    appointment.goal = item.goal && this.goalAdapter.adaptToObject(item.goal);
    appointment.block = item.block && this.blockAdapter.adaptToObject(item.block);

    if (item && item.collaborators) {
      const users: User[] = [];
      item.collaborators.forEach((element: any) => {
        let user: User;

        if (element.collaborator) {
          user = this.userAdapter.adaptToObject(element.collaborator);
          user.state = this.userStateAdapter.adaptToObject(element);
        } else {
          user = this.userAdapter.adaptToObject(element);
        }

        users.push(user);
      });
      appointment.users = users;
    }

    if (item && item.patients) {
      const patients: Patient[] = [];
      item.patients.forEach((element: any) => {
        let patient: Patient;
        if (element.patient) {
          patient = this.patientAdapter.adaptToObject(element.patient);
          patient.state = this.patientStateAdapter.adaptToObject(element);
        } else {
          patient = this.patientAdapter.adaptToObject(element);
        }
        patients.push(patient);
      });
      appointment.patients = patients;
    }

    if (item && item.registrations) {
      const registrations: AppointmentRegistration[] = [];

      item.registrations.forEach((element: any) => {
        registrations.push({
          id: element.id,
          birthdate: moment.tz(element.birthdate, 'UTC'),
          firstName: element.first_name,
          lastName: element.last_name,
          phone: element.phone,
          email: element.email,
        });
      });

      appointment.registrations = registrations;
    }

    appointment.deletedAt = item && moment.tz(item.deleted_at, 'UTC');
    appointment.modifiedAtOriginal = item && item.modified_at;

    if (item && item.created_by) {
      appointment.createdBy = this.userAdapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      appointment.modifiedBy = this.userAdapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      appointment.deletedBy = this.userAdapter.adaptToObject(item.deleted_by);
    }

    return appointment;
  }
  public adaptToRequest(item: Appointment): any {
    const object = {
      date: item.startDate.format('YYYY-MM-DD'),
      start_time: item.startDate.format('HH:mm'),
      end_time: item.endDate.format('HH:mm'),
      visibility: item.visibility.toString(),
      mark_occupied: item.markOccupied,
      flag_action: item.flagAction,
      description: item.description,
      comment: item.comment,
      address: item.address,
      color: item.color,
      title: null,
      therapy: null,
      office: null,
      room: null,
      goal: null,
      patients: [],
      collaborators: [],
    };

    if (item.id) {
      object[`id`] = item.id;
    }
    if (item.title) {
      object[`title`] = item.title;
    }
    if (item.therapy) {
      object[`therapy`] = item.therapy.id;
    }
    if (item.office) {
      object[`office`] = item.office.id;
    }
    if (item.room) {
      object[`room`] = item.room.id;
    }
    if (item.goal) {
      object[`goal`] = item.goal.id;
    }
    if (item.modifiedAtOriginal) {
      object[`modified_at`] = item.modifiedAtOriginal;
    }

    item.patients.forEach((patient: Patient) => {
      const p = this.patientStateAdapter.adaptToRequest(patient);
      object[`patients`].push(p);
    });

    item.users.forEach((user: User) => {
      const u = this.userStateAdapter.adaptToRequest(user);
      object[`collaborators`].push(u);
    });

    return object;
  }
}
