<mat-form-field class="d-flex flex-column" *transloco="let t">
  <mat-label>{{ t('user-or-group') | capitalize }}</mat-label>
  <input matInput type="text" [formControl]="control" [matAutocomplete]="auto" />
  <mat-error *ngIf="control.invalid">{{ t('validation.value-required') | capitalize }}</mat-error>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayItem">
    <mat-optgroup [label]="t('user') | capitalize">
      <mat-option *ngFor="let option of values[0]" [value]="option">
        <mat-icon>person</mat-icon>
        <span>{{ option.firstName }} {{ option.lastName }}</span>
        <span *ngIf="option.email"> ({{ option.email }})</span>
      </mat-option>
    </mat-optgroup>
    <mat-optgroup [label]="t('business-group') | capitalize">
      <mat-option *ngFor="let option of values[1]" [value]="option">
        <mat-icon>people</mat-icon>
        <span>{{ option.name }}</span>
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>
