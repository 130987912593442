import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppointmentFormBulkComponent } from './components/appointment-form-bulk/appointment-form-bulk.component';
import { CalendarFiltersComponent } from './components/calendar-filters/calendar-filters.component';
import { CalendarTooltipComponent } from './components/calendar-tooltip/calendar-tooltip.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConflictDialogComponent } from './components/conflict-dialog/conflict-dialog.component';
import { FormLoaderComponent } from './components/form-loader/form-loader.component';
import { GoalSelectComponent } from './components/goal-select/goal-select.component';
import { MultiCalendarFiltersComponent } from './components/multi-calendar-filters/multi-calendar-filters.component';
import { OfficeSelectBulkComponent } from './components/office-select-bulk/office-select-bulk.component';
import { OfficeSelectComponent } from './components/office-select/office-select.component';
import { PatientSelectComponent } from './components/patient-select/patient-select.component';
import { PatientsSelectComponent } from './components/patients-select/patients-select.component';
import { RoomSelectBulkComponent } from './components/room-select-bulk/room-select-bulk.component';
import { RoomSelectComponent } from './components/room-select/room-select.component';
import { SearchFiltersComponent } from './components/search-filters/search-filters.component';
import { SnackbarNotificationComponent } from './components/snack-notification/snack-notification.component';
import { TherapySelectBulkComponent } from './components/therapy-select-bulk/therapy-select-bulk.component';
import { TherapySelectComponent } from './components/therapy-select/therapy-select.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { UsersSelectBulkComponent } from './components/users-select-bulk/users-select-bulk.component';
import { UsersSelectComponent } from './components/users-select/users-select.component';
import { MaterialModule } from './material.module';
import { CapitalizePipe, SafeHtmlPipe, StringEnumToArrayPipe } from './pipes';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslocoModule,
        ColorPickerModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgbModule,
        // Pipes
        CapitalizePipe,
        StringEnumToArrayPipe,
        SafeHtmlPipe,
        TherapySelectComponent,
        RoomSelectComponent,
        PatientSelectComponent,
        PatientsSelectComponent,
        UserSelectComponent,
        UsersSelectComponent,
        GoalSelectComponent,
        OfficeSelectComponent,
        FormLoaderComponent,
        SnackbarNotificationComponent,
        ConfirmationDialogComponent,
        CalendarFiltersComponent,
        MultiCalendarFiltersComponent,
        SearchFiltersComponent,
        CalendarTooltipComponent,
        AppointmentFormBulkComponent,
        TherapySelectBulkComponent,
        OfficeSelectBulkComponent,
        RoomSelectBulkComponent,
        UsersSelectBulkComponent
    ],
    declarations: [
        TherapySelectComponent,
        RoomSelectComponent,
        PatientSelectComponent,
        PatientsSelectComponent,
        UserSelectComponent,
        UsersSelectComponent,
        GoalSelectComponent,
        OfficeSelectComponent,
        FormLoaderComponent,
        SnackbarNotificationComponent,
        ConfirmationDialogComponent,
        ConflictDialogComponent,
        CalendarFiltersComponent,
        MultiCalendarFiltersComponent,
        SearchFiltersComponent,
        CalendarTooltipComponent,
        AppointmentFormBulkComponent,
        TherapySelectBulkComponent,
        OfficeSelectBulkComponent,
        RoomSelectBulkComponent,
        UsersSelectBulkComponent,
        // Pipes
        CapitalizePipe,
        StringEnumToArrayPipe,
        SafeHtmlPipe
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: ''
            }
        }
    ]
})
export class SharedModule { }
