import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { PaginationParam } from 'src/app/data/interfaces';
import { Office, Search } from 'src/app/data/models';
import { OfficesService } from 'src/app/data/services';
import { CapitalizePipe } from '../../pipes';

@Component({
  selector: 'app-office-select-bulk',
  templateUrl: './office-select-bulk.component.html',
  styleUrls: ['./office-select-bulk.component.scss'],
})
export class OfficeSelectBulkComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() params: PaginationParam[] = [];

  private subscriber = new Subject();

  public offices: Office[];
  public defaultOffice: Office;

  constructor(
    private officesService: OfficesService,
    private capitalizePipe: CapitalizePipe,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.translocoService.selectTranslate('actual-value').subscribe((value: string) => {
      this.defaultOffice = new Office(
        -1,
        undefined,
        this.capitalizePipe.transform(value),
        undefined,
        undefined,
        undefined
      );

      this.defaultOffice.active = true;
    });

    this.control.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof Office) {
            return value as Office;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        switchMap((filter: string | Office) => {
          if (filter instanceof Office) {
            return of(filter);
          } else {
            const search = new Search(filter ? filter : '', this.params);
            return this.officesService.getOfficesWithoutPagination(search).pipe(takeUntil(this.subscriber));
          }
        })
      )
      .subscribe((value: Office[] | Office) => {
        if (!(value instanceof Office)) {
          this.offices = value;
          this.offices.splice(0, 0, this.defaultOffice);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayOffice(office: Office): string {
    return office && office.name ? office.name : '';
  }

  public onfocusIn(): void {
    if (this.control.value instanceof Office && this.control.value.id < 0 && this.control.untouched) {
      this.control.setValue('');
    }
  }
}
