import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { HttpParamsUtils, Office, OfficeAdapter } from '../models';

@Injectable({ providedIn: 'root' })
export class OfficesService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: OfficeAdapter
  ) { }

  private getOffices(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `offices/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getOfficesWithPagination(pagination: PaginationInterface, search?: SearchInterface): Observable<PaginationResults<Office>> {
    return this.getOffices(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const offices: Office[] = [];

        if (results.results) {
          for (const i of results.results) {
            offices.push(this.adapter.adaptToObject(i));
          }
          results.results = offices;

          return results as PaginationResults<Office>;
        }
      })
    );
  }

  public getOfficesWithoutPagination(search?: SearchInterface): Observable<Office[]> {
    return this.getOffices(undefined, search).pipe(
      map((results: any[]) => {
        const offices: Office[] = [];

        results.forEach(elem => {
          offices.push(this.adapter.adaptToObject(elem));
        });
        return offices;
      })
    );
  }
}
