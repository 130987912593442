<ng-container *transloco="let t">
  <div class="row no-gutters align-items-start justify-content-between">
    <div class="col pl-0">
      <mat-form-field class="w-100">
        <mat-label>{{ t('dates') | capitalize }}</mat-label>
        <input matInput readonly [value]="t('select-your-dates') | capitalize" />
        <button mat-icon-button matSuffix [matMenuTriggerFor]="datesMenu">
          <mat-icon>date_range</mat-icon>
        </button>
      </mat-form-field>
      <span class="font-weight-bolder">
        {{ selectedFilters.startDate ? (selectedFilters.startDate | date: 'dd.MM.yyyy') : '-' }}
        {{ t('to') }}
        {{ selectedFilters.endDate ? (selectedFilters.endDate | date: 'dd.MM.yyyy') : '-' }}
      </span>
      <mat-menu #datesMenu="matMenu">
        <div class="w-100 d-flex flex-column px-3">
          <h2>{{ t('dates') | capitalize }}</h2>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ t('start-date') | capitalize }}</mat-label>
            <input matInput [formControl]="control.controls.startDate" [matDatepicker]="startPicker"
              (click)="$event.stopPropagation()" />
            <mat-error *ngIf="control.controls.startDate.invalid">{{ t('validation.value-required') | capitalize }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ t('end-date') | capitalize }}</mat-label>
            <input matInput [formControl]="control.controls.endDate" [matDatepicker]="endPicker"
              (click)="$event.stopPropagation()" />
            <mat-error *ngIf="control.controls.endDate.invalid">{{ t('validation.value-required') | capitalize }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-menu>
    </div>
    <div class="col">
      <app-office-select [control]="control.controls.office"></app-office-select>
      <mat-chip-list>
        <mat-chip *ngFor="let office of selectedFilters.offices; trackBy: trackByModelId" [removable]="true"
          (removed)="removeOfficeFromFilters(office)">{{ office.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span *ngIf="selectedFilters.offices.length === 0" class="font-weight-bolder">
        {{ t('all') | capitalize }}
      </span>
    </div>
    <div class="col">
      <app-room-select [control]="control.controls.room"></app-room-select>
      <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let room of selectedFilters.rooms; trackBy: trackByModelId" [removable]="true"
          (removed)="removeRoomFromFilters(room)">{{ room.name }} ({{ room.office ? room.office.name : '-' }})
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span *ngIf="selectedFilters.rooms.length === 0" class="font-weight-bolder">
        {{ t('all') | capitalize }}
      </span>
    </div>
    <div class="col">
      <app-therapy-select [control]="control.controls.therapy"></app-therapy-select>
      <mat-chip-list>
        <mat-chip *ngFor="let therapy of selectedFilters.therapies; trackBy: trackByModelId" [removable]="true"
          (removed)="removeTherapyFromFilters(therapy)">{{ therapy.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span *ngIf="selectedFilters.therapies.length === 0" class="font-weight-bolder">
        {{ t('all') | capitalize }}
      </span>
    </div>
    <div class="col">
      <app-goal-select [control]="control.controls.goal"></app-goal-select>
      <mat-chip-list>
        <mat-chip *ngFor="let goal of selectedFilters.goals; trackBy:trackByModelId" [removable]="true"
          (removed)="removeGoalFromFilters(goal)">{{goal.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span *ngIf="selectedFilters.goals.length === 0" class="font-weight-bolder">
        {{t('all') | capitalize}}
      </span>
    </div>
    <div class="col">
      <app-user-select [control]="control.controls.user"></app-user-select>
      <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let user of selectedFilters.users; trackBy: trackByModelId" [removable]="true"
          (removed)="removeUserFromFilters(user)">{{ user.getDisplayName() }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span *ngIf="selectedFilters.users.length === 0" class="font-weight-bolder">
        {{ t('all') | capitalize }}
      </span>
    </div>
    <div class="col">
      <app-patient-select [control]="control.controls.patient"></app-patient-select>
      <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let patient of selectedFilters.patients; trackBy: trackByModelId" [removable]="true"
          (removed)="removePatientFromFilters(patient)">{{ patient.getDisplayName() }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span *ngIf="selectedFilters.patients.length === 0" class="font-weight-bolder">
        {{ t('all') | capitalize }}
      </span>
    </div>
    <div class="col-auto pr-0">
      <button mat-icon-button [matMenuTriggerFor]="specialMenu">
        <mat-icon>filter_list</mat-icon>
      </button>
      <mat-menu #specialMenu="matMenu" class="filters-menu">
        <div class="w-100 d-flex flex-column px-3">
          <h2>{{ t('special-filters.title') | capitalize }}</h2>
          <mat-checkbox [formControl]="control.controls.free" (click)="$event.stopPropagation()">
            {{ t('special-filters.free-appointments') | capitalize }}</mat-checkbox>
          <mat-checkbox [formControl]="control.controls.personal" (click)="$event.stopPropagation()">
            {{ t('special-filters.appointments-without-therapy') | capitalize }}</mat-checkbox>
          <mat-checkbox [formControl]="control.controls.action" (click)="$event.stopPropagation()">
            {{ t('special-filters.appointments-need-actions') | capitalize }}</mat-checkbox>
          <mat-checkbox [formControl]="control.controls.no_show" (click)="$event.stopPropagation()">
            {{ t('special-filters.patients-no-show') | capitalize }}</mat-checkbox>
          <mat-checkbox [formControl]="control.controls.flag_action" (click)="$event.stopPropagation()">
            {{ t('special-filters.appointments-action-flag') | capitalize }}</mat-checkbox>
          <mat-checkbox [formControl]="control.controls.action_and_public" (click)="$event.stopPropagation()">
            {{ t('special-filters.appointments-action-and-public') | capitalize }}</mat-checkbox>
        </div>
      </mat-menu>
    </div>
  </div>
</ng-container>