import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/core/services';

@Injectable({ providedIn: 'root' })
export class ReportingService {
  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  public getReporting(dateFrom: string, dateTo: string): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `reporting/`;
    let params = new HttpParams();
    params = params.append('date_from', dateFrom);
    params = params.append('date_to', dateTo);
    return this.httpClient.get(url, { params: params, responseType: 'blob' });
  }
}
