import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbilityModule } from '@casl/angular';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuard, GuestGuard, PermissionGuard } from './guards';
import { OAuthCustomModule } from './oauth.module';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AbilityModule,
    OAuthCustomModule.forRoot(),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },
    AuthGuard,
    GuestGuard,
    PermissionGuard
  ],
})
export class CoreModule { }
