import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-logout.dialog',
  templateUrl: './logout.dialog.component.html',
})
export class LogoutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oauthService: OAuthService
  ) {}

  public logout(): void {
    this.oauthService.logOut();
  }
}
