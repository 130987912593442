import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/core/services';

@Injectable({ providedIn: 'root' })
export class SynchronizationService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService
  ) { }

  public syncUsers(): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `sync/sync_users/`;
    return this.httpClient.post(url, {});
  }

  public syncPatients(): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `sync/sync_patients/`;
    return this.httpClient.post(url, {});
  }

  public syncTherapies(): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `sync/sync_therapies/`;
    return this.httpClient.post(url, {});
  }

  public syncOffices(): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `sync/sync_offices/`;
    return this.httpClient.post(url, {});
  }

  public syncRooms(): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `sync/sync_rooms/`;
    return this.httpClient.post(url, {});
  }
  public syncGoals(): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `sync/sync_goals/`;
    return this.httpClient.post(url, {});
  }
}
