import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BusinessGroup, User } from 'src/app/data/models';

export function userGroupRequiredValidator(): ValidatorFn {
  const validator: ValidatorFn = (control: AbstractControl) => {
    const value = control.value;
    return value instanceof User ||
      value instanceof BusinessGroup ||
      value === null ||
      value.trim().toLowerCase() === ''
      ? null
      : { required: true };
  };

  return validator;
}
