import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { convert, userRequiredValidator } from 'src/app/core/validators';
import { Search, User } from 'src/app/data/models';
import { UsersService } from 'src/app/data/services';

@Component({
  selector: 'app-users-select-bulk',
  templateUrl: './users-select-bulk.component.html',
  styleUrls: ['./users-select-bulk.component.scss']
})

export class UsersSelectBulkComponent implements OnInit, OnDestroy {
  @Input() control: FormArray;
  public userControl: FormControl;

  private subscriber = new Subject();

  public users: User[];

  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.userControl = new FormControl('', userRequiredValidator());
    this.userControl.valueChanges.pipe(
      takeUntil(this.subscriber),
      debounceTime(250),
      distinctUntilChanged(),
      map((value: any) => {
        if (value instanceof User) {
          return value as User;
        } else {
          const final = value.trim().toLowerCase();
          return final !== '' ? final : undefined;
        }
      }),
      filter((value: string | User) => (value instanceof User) || (value && value.length > 3)),
      switchMap((value: string | User) => {
        if (value instanceof User) {
          return of(value);
        } else if (value) {
          const v = convert(value);
          const search = new Search(v);

          search.params.push({ name: 'active', value: true });
          return this.usersService.getUsersWithoutPagination(search).pipe(takeUntil(this.subscriber));
        }
      })
    ).subscribe((value: User[] | User) => {
      if (value instanceof User) {
        if (!this.control.value.some((u: User) => value.id === u.id)) {
          const userControl = new FormControl('', userRequiredValidator());
          userControl.setValue(value);
          this.control.push(userControl);
        }

        this.userControl.patchValue('', { emitEvent: false, onlySelf: true });
        this.users = [];
      } else {
        this.users = value;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayUser(user: User): string {
    return user ? user.getDisplayName() : '';
  }

  public getUserFormValue(index: number): string {
    const user = (this.control.at(index).value as User);
    return user.getDisplayName();
  }
  public removeUserFromForm(index: number): void {
    this.control.removeAt(index);
  }
}
