import { AbstractControl, ValidatorFn } from '@angular/forms';

export function daysOfMonthValidator(): ValidatorFn {
  const validator: ValidatorFn = (control: AbstractControl) => {
    return checkDaysOfMonthArrayValidity(control.value) ? null : { required: true };
  };

  return validator;
}

export function checkDaysOfMonthArrayValidity(value: string): boolean {
  const values = value.split(',');
  try {
    return !values.some((day: string) => !Number(day) || Number(day) > 31 || Number(day) < 1);
  } catch (error) {
    return false;
  }
}

export function getDaysOfMonthNumberArrayFromString(value: string): number[] {
  const array: number[] = [];
  const values = value.split(',');

  values.forEach(day => array.push(Number(day)));

  return array;
}

