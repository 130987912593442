import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  public logoUrl: string;

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.logoUrl = this.settingsService.getSettings().logoUrl;
  }
}
