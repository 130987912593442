import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { convert, patientRequiredValidator } from 'src/app/core/validators';
import { Patient, PatientAppointmentStateEnum, Search } from 'src/app/data/models';
import { BusinessService, PatientsService } from 'src/app/data/services';

export interface ControlState {
  color: string;
  selected: boolean;
}

@Component({
  selector: 'app-patients-select',
  templateUrl: './patients-select.component.html',
  styleUrls: ['./patients-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PatientsSelectComponent implements OnInit, OnDestroy {
  @Input() control: FormArray;
  @Input() defaultState?: PatientAppointmentStateEnum | null;

  public patientControl: FormControl;

  private subscriber = new Subject();

  public patients: Patient[];
  public patientStates = PatientAppointmentStateEnum;

  constructor(private patientsService: PatientsService, private businessService: BusinessService) {}

  ngOnInit() {
    this.patientControl = new FormControl('', patientRequiredValidator());
    this.patientControl.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof Patient) {
            return value as Patient;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        filter(
          (value: string | Patient) => value instanceof Patient || (value && value.trim().toLowerCase().length > 2)
        ),
        switchMap((value: string | Patient) => {
          if (value instanceof Patient) {
            return of(value);
          } else if (value) {
            const v = convert(value);
            const search = new Search(v);

            search.params.push({ name: 'active', value: true });
            return this.patientsService.getPatientsWithoutPagination(search).pipe(takeUntil(this.subscriber));
          } else {
            return this.patientsService.getPatientsWithoutPagination().pipe(takeUntil(this.subscriber));
          }
        })
      )
      .subscribe((value: Patient[] | Patient) => {
        if (value instanceof Patient) {
          if (!this.control.value.some((p: Patient) => value.id === p.id)) {
            value.state = { state: this.defaultState ? this.defaultState : PatientAppointmentStateEnum.REGISTERED };
            const patientControl = new FormControl('', patientRequiredValidator());
            patientControl.setValue(value);

            this.control.push(patientControl);
          }

          this.patientControl.patchValue('', { emitEvent: false, onlySelf: true });
          this.patients = [];
        } else {
          this.patients = value;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayPatient(patient: Patient): string {
    return patient ? patient.getDisplayName() : '';
  }

  public getPatientFormValue(index: number): string {
    const patient = this.control.at(index).value as Patient;
    return patient.getDisplayName();
  }

  public removePatientFromForm(index: number): void {
    this.control.removeAt(index);
  }

  public canShowState(i: number, state: PatientAppointmentStateEnum): boolean {
    const patient = this.control.at(i).value as Patient;
    return this.businessService.canPatientChangeToStateInAppointment(patient, state);
  }

  public needTranslator(i: number): boolean {
    const patient = this.control.at(i).value as Patient;
    return patient.needTranslator === true;
  }

  public changeState(i: number, state: PatientAppointmentStateEnum, event: Event): void {
    event.stopPropagation();

    const p = this.control.at(i).value as Patient;
    p.state.state = state;

    this.control.at(i).setValue(p);
  }

  public getState(i: number): ControlState {
    const p = this.control.at(i).value as Patient;
    const c = this.businessService.getPatientStateColor(p);

    return { color: c, selected: !!c };
  }

  public openExternalView(i: number): void {
    const p = this.control.at(i).value as Patient;

    this.patientsService
      .getPatientExternalUri(p)
      .pipe(take(1))
      .subscribe((uri: string) => {
        window.open(uri, '_blank');
      });
  }
}
