import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { HttpParamsUtils, Room, RoomAdapter } from '../models';

@Injectable({ providedIn: 'root' })
export class RoomsService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: RoomAdapter
  ) { }

  private getRooms(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `rooms/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getRoomsWithPagination(pagination: PaginationInterface, search?: SearchInterface): Observable<PaginationResults<Room>> {
    return this.getRooms(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const rooms: Room[] = [];

        if (results.results) {
          for (const i of results.results) {
            rooms.push(this.adapter.adaptToObject(i));
          }
          results.results = rooms;

          return results as PaginationResults<Room>;
        }
      })
    );
  }

  public getRoomsWithoutPagination(search?: SearchInterface): Observable<Room[]> {
    return this.getRooms(undefined, search).pipe(
      map((results: any[]) => {
        const rooms: Room[] = [];

        results.forEach(elem => {
          rooms.push(this.adapter.adaptToObject(elem));
        });
        return rooms;
      })
    );
  }
}
