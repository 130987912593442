<ng-container *transloco="let t;">
  <mat-form-field class="d-flex flex-column">
    <mat-label>{{t('user') | capitalize}}</mat-label>
    <input matInput type="text" [formControl]="userControl" [matAutocomplete]="auto" />
    <mat-error *ngIf="userControl.invalid">{{t('validation.must-be-valid-user') | capitalize}}</mat-error>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayUser">
      <mat-option *ngFor="let option of users" [value]="option">
        <span>{{option.firstName}} {{option.lastName}}</span>
        <span *ngIf="option.email"> ({{option.email}})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="my-3">
    <h4>{{t('new-users') | capitalize}} :</h4>
    <mat-chip-list aria-label="Users">
      <mat-chip *ngFor="let item of control.controls; let i = index" [removable]="true" [selectable]="false"
        (removed)="removeUserFromForm(i)">
        <mat-icon matChipAvatar>group</mat-icon>
        {{getUserFormValue(i)}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-container>
