import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringEnumToArray'
})
export class StringEnumToArrayPipe implements PipeTransform {
  transform(data: object) {
    const keys = Object.keys(data);
    const values = Object.values(data);
    const array = [];
    keys.forEach((element, index) => {
      array.push({ key: element, value: values[index] });
    });
    return array;
  }
}
