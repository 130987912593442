import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKey, StorageService } from 'src/app/core/services';
import { BusinessGroup, Goal, Office, Patient, Room, Therapy, User } from 'src/app/data/models';
import { CalendarFiltersSelectedData } from '../calendar-filters/calendar-filters.component';

export interface SearchFiltersSelectedData extends CalendarFiltersSelectedData {
  startDate: Date;
  endDate: Date;
  free: boolean;
  action: boolean;
  personal: boolean;
  no_show: boolean;
  flag_action: boolean;
  action_and_public: boolean;
}

@Component({
  selector: 'app-search-filters',
  templateUrl: 'search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit {
  @Input() control: FormGroup;

  @Output() selectedFiltersEmitter: EventEmitter<SearchFiltersSelectedData> = new EventEmitter();

  private subscriber = new Subject();

  private user: User;

  public selectedFilters: SearchFiltersSelectedData = {
    startDate: new Date(),
    endDate: undefined,
    users: [],
    patients: [],
    offices: [],
    rooms: [],
    therapies: [],
    goals: [],
    free: false,
    action: false,
    personal: false,
    no_show: false,
    flag_action: false,
    action_and_public: false,
  };

  constructor(protected route: ActivatedRoute, private storageService: StorageService) {}

  public ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.subscriber)).subscribe((data: Data) => {
      if (data && data.user) {
        this.user = data.user;
        this.initFormListeners();
      }

      if (data && data.patient) {
        this.selectedFilters.patients = [data.patient];
      }
    });
  }

  private initFormListeners(): void {
    if (localStorage.getItem(StorageKey.SEARCH_COMPONENT + this.user.id)) {
      this.initFiltersFromStorage();
    }

    this.control
      .get('startDate')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: string | moment.Moment) => {
        if (moment.isMoment(value)) {
          this.selectedFilters.startDate = value.toDate();
        } else if (!value || value === '') {
          this.selectedFilters.startDate = undefined;
        }

        this.emitData();
      });

    this.control
      .get('endDate')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: string | moment.Moment) => {
        if (moment.isMoment(value)) {
          this.selectedFilters.endDate = value.toDate();
        } else if (!value || value === '') {
          this.selectedFilters.endDate = undefined;
        }

        this.emitData();
      });

    this.control
      .get('therapy')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Therapy | string) => {
        if (value instanceof Therapy) {
          this.control.get('therapy').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.therapies.some((v: Therapy) => v.id === value.id)) {
            this.selectedFilters.therapies.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('office')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Office | string) => {
        if (value instanceof Office) {
          this.control.get('office').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.offices.some((v: Office) => v.id === value.id)) {
            this.selectedFilters.offices.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('room')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Room | string) => {
        if (value instanceof Room) {
          this.control.get('room').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.rooms.some((v: Room) => v.id === value.id)) {
            this.selectedFilters.rooms.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('user')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: User | BusinessGroup | string) => {
        if (value instanceof User || value instanceof BusinessGroup) {
          this.control.get('user').patchValue('', { emitEvent: false, onlySelf: true });

          if (value instanceof User) {
            if (!this.selectedFilters.users.some((v: User) => v.id === value.id)) {
              this.selectedFilters.users.push(value);
            }
          }

          if (value instanceof BusinessGroup) {
            const values = value.users.filter(
              (user) => !this.selectedFilters.users.some((u: User) => user.id === u.id)
            );
            this.selectedFilters.users.push(...values);
          }

          this.emitData();
        }
      });

    this.control
      .get('patient')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Patient | string) => {
        if (value instanceof Patient) {
          this.control.get('patient').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.patients.some((v: Patient) => v.id === value.id)) {
            this.selectedFilters.patients.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('goal')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Goal | string) => {
        if (value instanceof Goal) {
          this.control.get('goal').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.goals.some((v: Goal) => v.id === value.id)) {
            this.selectedFilters.goals.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('free')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: boolean) => {
        this.selectedFilters.free = value;
        this.emitData();
      });

    this.control
      .get('action')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: boolean) => {
        this.selectedFilters.action = value;
        this.emitData();
      });

    this.control
      .get('personal')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: boolean) => {
        this.selectedFilters.personal = value;
        this.emitData();
      });

    this.control
      .get('no_show')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: boolean) => {
        this.selectedFilters.no_show = value;
        this.emitData();
      });

    this.control
      .get('flag_action')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: boolean) => {
        this.selectedFilters.flag_action = value;
        this.emitData();
      });

    this.control
      .get('action_and_public')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: boolean) => {
        this.selectedFilters.action_and_public = value;
        this.emitData();
      });

    this.emitData();
  }

  public removeGoalFromFilters(value: Goal): void {
    const index = this.selectedFilters.goals.findIndex((v: Goal) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.goals.splice(index, 1);
      this.emitData();
    }
  }

  public removePatientFromFilters(value: Patient): void {
    const index = this.selectedFilters.patients.findIndex((v: Patient) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.patients.splice(index, 1);
      this.emitData();
    }
  }

  public removeUserFromFilters(value: User): void {
    const index = this.selectedFilters.users.findIndex((v: User) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.users.splice(index, 1);
      this.emitData();
    }
  }

  public removeTherapyFromFilters(value: Therapy): void {
    const index = this.selectedFilters.therapies.findIndex((v: Therapy) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.therapies.splice(index, 1);
      this.emitData();
    }
  }

  public removeOfficeFromFilters(value: Office): void {
    const index = this.selectedFilters.offices.findIndex((v: Office) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.offices.splice(index, 1);
      this.emitData();
    }
  }

  public removeRoomFromFilters(value: Room): void {
    const index = this.selectedFilters.rooms.findIndex((v: Room) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.rooms.splice(index, 1);
      this.emitData();
    }
  }

  public trackByModelId(index: number, name: any): number {
    return name.id;
  }

  private emitData(): void {
    this.storageService.storeFilters(StorageKey.SEARCH_COMPONENT, this.user, this.selectedFilters);
    this.selectedFiltersEmitter.emit(this.selectedFilters);
  }

  private initFiltersFromStorage(): void {
    const lastFilters: SearchFiltersSelectedData = this.storageService.loadFilters(
      StorageKey.SEARCH_COMPONENT,
      this.user
    ) as SearchFiltersSelectedData;

    this.selectedFilters = lastFilters;

    this.control.get('free').setValue(lastFilters.free);
    this.control.get('action').setValue(lastFilters.action);
    this.control.get('personal').setValue(lastFilters.personal);
    this.control.get('no_show').setValue(lastFilters.no_show);
    this.control.get('flag_action').setValue(lastFilters.flag_action);
    this.control.get('action_and_public').setValue(lastFilters.action_and_public);

    if (lastFilters.startDate) {
      this.control.get('startDate').setValue(new Date(lastFilters.startDate));
    }

    if (lastFilters.endDate) {
      this.control.get('endDate').setValue(new Date(lastFilters.endDate));
    }
  }
}
