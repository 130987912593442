import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter, ExternalEntityInterface, HttpEntityInterface } from '../interfaces';
import { User, UserAdapter } from './user';

export class Therapy implements HttpEntityInterface, ExternalEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public name: string,
    public defaultDuration: moment.Moment,
    public color: string,
    public createdAt: moment.Moment,
    public modifiedAt: moment.Moment,

    public externalId?: number,
    public description?: string,
    public createdBy?: User,
    public modifiedBy?: User,
    public deletedAt?: moment.Moment,
    public deletedBy?: User,

    public active?: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class TherapyAdapter implements Adapter<Therapy> {
  constructor(private adapter: UserAdapter) {}

  public adaptToObject(item: any): Therapy {
    const therapy = new Therapy(
      item.id,
      item.url,
      item.name,
      moment(item.default_duration, [moment.ISO_8601, 'HH:mm']),
      item.color,
      moment.tz(item.created_at, 'UTC'),
      moment.tz(item.modified_at, 'UTC')
    );

    therapy.externalId = item && item.external_id;
    therapy.deletedAt = item && moment.tz(item.deleted_at, 'UTC');

    if (item && item.created_by) {
      therapy.createdBy = this.adapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      therapy.modifiedBy = this.adapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      therapy.deletedBy = this.adapter.adaptToObject(item.deleted_by);
    }

    if (item && item.active !== null && item.active !== undefined) {
      therapy.active = item.active;
    }

    return therapy;
  }
  public adaptToRequest(item: Therapy): any {
    const object = {
      name: item.name,
      default_duration: item.defaultDuration.format('HH:mm:ss'),
      description: item.description,
      active: item.active,
    };

    if (item.id) {
      object[`id`] = item.id;
    }

    return object;
  }
}
