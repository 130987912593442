import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { HttpParamsUtils, Therapy, TherapyAdapter } from '../models';

@Injectable({ providedIn: 'root' })
export class TherapiesService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: TherapyAdapter
  ) { }

  private getTherapies(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `therapies/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getTherapiesWithPagination(pagination: PaginationInterface, search?: SearchInterface): Observable<PaginationResults<Therapy>> {
    return this.getTherapies(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const therapies: Therapy[] = [];

        if (results.results) {
          for (const i of results.results) {
            therapies.push(this.adapter.adaptToObject(i));
          }
          results.results = therapies;

          return results as PaginationResults<Therapy>;
        }
      })
    );
  }

  public getTherapiesWithoutPagination(search?: SearchInterface): Observable<Therapy[]> {
    return this.getTherapies(undefined, search).pipe(
      map((results: any[]) => {
        const therapies: Therapy[] = [];

        results.forEach(elem => {
          therapies.push(this.adapter.adaptToObject(elem));
        });
        return therapies;
      })
    );
  }

  public getTherapy(id: number | string): Observable<Therapy> {
    const url = this.settingsService.getSettings().baseUrl + `therapies/${id}/`;

    return this.httpClient.get(url, { }).pipe(
      map((result: any) => {
        return this.adapter.adaptToObject(result);
      })
    );
  }

  public updateTherapy(therapy: Therapy): Observable<Therapy> {
    const url = this.settingsService.getSettings().baseUrl + `therapies/${therapy.id}/`;

    return this.httpClient.put(url, this.adapter.adaptToRequest(therapy)).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }
}
