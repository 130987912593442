import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class GuestGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuthenticated();
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuthenticated();
  }
  isAuthenticated(): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated().pipe(
      map((auth: boolean) => {
        if (auth === true) {
          return this.router.parseUrl('/planning');
        }

        return true;
      })
    );
  }
}
