import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Conflict } from 'src/app/data/models/conflict';

export interface ConflictDialogComponentData {
  title: string;
  message: string;
  conflicts: Conflict[];
}

@Component({
  selector: 'app-conflict-dialog',
  templateUrl: './conflict-dialog.component.html',
  styleUrls: ['./conflict-dialog.component.scss'],
})
export class ConflictDialogComponent {
  public title: string;
  public message: string;

  public conflicts: Conflict[];

  constructor(
    public dialogRef: MatDialogRef<ConflictDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConflictDialogComponentData
  ) {
    if (this.data.message !== null && this.data.message !== undefined) {
      this.message = this.data.message;
    }

    if (this.data.title !== null && this.data.title !== undefined) {
      this.title = this.data.title;
    }

    if (this.data.conflicts !== null && this.data.conflicts !== undefined) {
      this.conflicts = this.data.conflicts;
      localStorage.setItem('conflicts', JSON.stringify(this.conflicts));
    }
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
  public confirm(): void {
    this.dialogRef.close(true);
  }
}
