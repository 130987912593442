import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, Injectable, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpEntityType } from 'src/app/data/enums';
import { ComparableItem } from 'src/app/data/interfaces';
import { Office, Room, Search, Therapy, User } from 'src/app/data/models';
import { OfficesService, RoomsService, TherapiesService, UsersService } from 'src/app/data/services';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: ComparableItem, public level = 1, public expandable = false,
    public isLoading = false
  ) { }
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({ providedIn: 'root' })
export class DynamicDatabase {

  constructor(
    private roomsService: RoomsService,
    private officesService: OfficesService,
    private therapiesService: TherapiesService,
    private usersService: UsersService
  ) { }

  rootLevelNodes: ComparableItem[] = [
    {
      type: HttpEntityType.OFFICE,
      item: new Office(-1, undefined, 'office', undefined, undefined, undefined)
    },
    /*
    {
      type: HttpEntityType.ROOM,
      item: new Room(-2, undefined, 'room', undefined, undefined)
    },
    */
    {
      type: HttpEntityType.THERAPY,
      item: new Therapy(-3, undefined, 'therapy', undefined, undefined, undefined, undefined)
    },
    {
      type: HttpEntityType.USER,
      item: new User(-4, undefined, undefined, 'user', '')
    },
  ];

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return this.rootLevelNodes.map(item => new DynamicFlatNode(item, 0, true));
  }

  getChildren(node: DynamicFlatNode): Observable<ComparableItem[]> {
    switch (node.item.type) {
      case HttpEntityType.ROOM:
        return this.roomsService.getRoomsWithoutPagination().pipe(
          map((rooms: Room[]) => {
            return rooms.map((room: Room) => ({ type: HttpEntityType.ROOM, item: room } as ComparableItem));
          })
        );
      case HttpEntityType.THERAPY:
        return this.therapiesService.getTherapiesWithoutPagination().pipe(
          map((therapies: Therapy[]) => {
            return therapies.map((therapy: Therapy) => ({ type: HttpEntityType.THERAPY, item: therapy } as ComparableItem));
          })
        );
      case HttpEntityType.USER:
        const s = new Search();
        s.params.push({ name: 'active', value: true });

        return this.usersService.getUsersWithoutPagination(s).pipe(
          map((users: User[]) => {
            return users.map((user: User) => ({ type: HttpEntityType.USER, item: user } as ComparableItem));
          })
        );
      case HttpEntityType.OFFICE:
        if (node.level <= 0) {
          return this.officesService.getOfficesWithoutPagination().pipe(
            map((offices: Office[]) => {
              return offices.map((office: Office) => ({ type: HttpEntityType.OFFICE, item: office } as ComparableItem));
            })
          );
        } else {
          const search = new Search();
          search.params.push({
            name: 'office',
            value: node.item.item.id
          });

          return this.roomsService.getRoomsWithoutPagination(search).pipe(
            map((rooms: Room[]) => {
              return rooms.map((room: Room) => ({ type: HttpEntityType.ROOM, item: room } as ComparableItem));
            })
          );
        }
      default:
        return of([]);
    }
  }

  isExpandable(node: ComparableItem): boolean {
    return node.item.id < 0;
  }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {

  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] { return this.dataChange.value; }
  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private treeControl: FlatTreeControl<DynamicFlatNode>, private database: DynamicDatabase) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    node.isLoading = true;
    const index = this.data.findIndex((n: DynamicFlatNode) => n.item.item.id === node.item.item.id);

    if (expand) {
      const children = this.database.getChildren(node);
      children.pipe(take(1)).subscribe((items: ComparableItem[]) => {
        const nodes = items.map(item =>
          new DynamicFlatNode(item, node.level + 1, item.type === HttpEntityType.OFFICE));
        this.data.splice(index + 1, 0, ...nodes);

        this.dataChange.next(this.data);
        node.isLoading = false;
      });
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) { }
      this.data.splice(index + 1, count);

      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }
}

@Component({
  selector: 'app-multi-calendar-filters',
  templateUrl: 'multi-calendar-filters.component.html',
  styleUrls: ['multi-calendar-filters.component.scss']
})

export class MultiCalendarFiltersComponent implements OnInit, AfterViewInit {
  @Input() control: FormControl;
  @Input() initialFilters: ComparableItem[];

  public treeControl: FlatTreeControl<DynamicFlatNode>;
  public dataSource: DynamicDataSource;

  public checklistSelection = new SelectionModel<DynamicFlatNode>(true, []);

  constructor(database: DynamicDatabase) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, database);

    this.dataSource.data = database.initialData();
  }

  ngOnInit() {
    this.dataSource.dataChange.subscribe((items: DynamicFlatNode[]) => {
      items.forEach((item: DynamicFlatNode) => {
        const existingNodes = this.checklistSelection.selected.filter((n: DynamicFlatNode) =>
          n.level === item.level && n.item.type === item.item.type && n.item.item.id === item.item.item.id);

        if ((existingNodes && existingNodes.length > 0) || this.checklistSelection.isSelected(this.getParentNode(item))) {
          this.checklistSelection.deselect(...existingNodes);
          this.checklistSelection.select(item);
        }
      });
    });

    this.checklistSelection.changed.pipe().subscribe(() => {
      const filters: ComparableItem[] = [];
      const list = this.checklistSelection.selected.sort((a, b) => a.item.item.id - b.item.item.id);

      list.forEach((item: DynamicFlatNode) => {
        const exists = filters.some((i: ComparableItem) => i.item.id === item.item.item.id && i.type === item.item.type);
        const canBeAdded = !filters.some((i: ComparableItem) => i.item.id < 0 && i.type === item.item.type);

        if (!exists && canBeAdded) {
          filters.push(item.item);
        }
      });

      this.control.patchValue(filters, { emitEvent: true, onlySelf: false });
    });
  }

  ngAfterViewInit() {
    if (this.initialFilters) {
      this.initialFilters.forEach(i => this.checklistSelection.select(new DynamicFlatNode(i, i.item.id < 0 ? 0 : 1, i.item.id < 0)));
    }
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, nodeData: DynamicFlatNode) => nodeData.expandable;

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: DynamicFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return (descAllSelected && descendants.length > 0) || this.checklistSelection.isSelected(node);
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: DynamicFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  comparableLeafItemSelectionToggle(node: DynamicFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  comparableItemSelectionToggle(node: DynamicFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: DynamicFlatNode): void {
    let parent: DynamicFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: DynamicFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: DynamicFlatNode): DynamicFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  public getNameFromItem(item: ComparableItem): string {
    switch (item.type) {
      case HttpEntityType.USER:
        return (item.item as User).getDisplayName();
      case HttpEntityType.PATIENT:
        return (item.item as User).getDisplayName();
      default:
        return item.item[`name`];
    }
  }
}
