<ng-container *transloco="let t">
  <mat-form-field class="d-flex flex-column">
    <mat-label>{{ t('patient') | capitalize }}</mat-label>
    <input matInput type="text" [formControl]="patientControl" [matAutocomplete]="auto" />
    <mat-error *ngIf="patientControl.invalid">{{ t('validation.must-be-valid-user') | capitalize }}</mat-error>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayPatient">
      <mat-option *ngFor="let option of patients" [value]="option" class="patients-autocomplete">
        <span>{{ option.getDisplayName() }}</span><br/>
        <span *ngIf="option.birthdate" class="text-muted"> ({{ option.birthdate | date: 'dd.MM.yyyy' }})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="my-3">
    <mat-chip-list aria-label="Patients" [multiple]="true">
      <mat-chip
        *ngFor="let item of control.controls; let i = index"
        [removable]="true"
        [selectable]="true"
        [selected]="getState(i).selected"
        [color]="getState(i).color"
        (removed)="removePatientFromForm(i)"
        (click)="openExternalView(i)"
      >
        <mat-icon matChipAvatar>face</mat-icon>
        <mat-icon
          matChipAvatar
          *ngIf="needTranslator(i)"
          [matTooltip]="t('appointments.patients.need-translator') | capitalize"
          matTooltipClass="state-tooltip"
          >translate</mat-icon
        >
        <span class="name">{{ getPatientFormValue(i) }}</span>
        <span class="ml-3 mr-1">&nbsp;|&nbsp;</span>
        <mat-icon
          matChipTrailingIcon
          *ngIf="canShowState(i, patientStates.TO_PLAN)"
          (click)="changeState(i, patientStates.TO_PLAN, $event)"
          [matTooltip]="t('appointments.patients.to-plan') | capitalize"
          matTooltipClass="state-tooltip"
          >schedule
        </mat-icon>
        <mat-icon
          matChipTrailingIcon
          *ngIf="canShowState(i, patientStates.DONE)"
          (click)="changeState(i, patientStates.DONE, $event)"
          [matTooltip]="t('appointments.patients.done') | capitalize"
          matTooltipClass="state-tooltip"
          >done
        </mat-icon>
        <mat-icon
          matChipTrailingIcon
          *ngIf="canShowState(i, patientStates.CANCELED)"
          (click)="changeState(i, patientStates.CANCELED, $event)"
          [matTooltip]="t('appointments.patients.cancel') | capitalize"
          matTooltipClass="state-tooltip"
          >event_busy
        </mat-icon>
        <mat-icon
          matChipTrailingIcon
          *ngIf="canShowState(i, patientStates.NO_SHOW)"
          (click)="changeState(i, patientStates.NO_SHOW, $event)"
          [matTooltip]="t('appointments.patients.no-show') | capitalize"
          matTooltipClass="state-tooltip"
          >report_off</mat-icon
        >
        <mat-icon
          matChipTrailingIcon
          *ngIf="canShowState(i, patientStates.REGISTERED)"
          (click)="changeState(i, patientStates.REGISTERED, $event)"
          [matTooltip]="t('appointments.patients.register') | capitalize"
          matTooltipClass="state-tooltip"
          >redo
        </mat-icon>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-container>
