import { FormArray, ValidatorFn } from '@angular/forms';

export function checkboxGroupRequiredValidator(): ValidatorFn {
  const validator: ValidatorFn = (formArray: FormArray) => {

    const selectedCount = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);

    return selectedCount >= 1 ? null : { notSelected: true };
  };

  return validator;
}
