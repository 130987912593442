import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarConfigs, SnackbarNotificationComponent, SnackbarNotificationDataInterface } from 'src/app/shared/components/snack-notification/snack-notification.component';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(
    private snackBar: MatSnackBar
  ) { }

  public openSuccessSnackbar(messageToDisplay: string): void {
    const config = SnackbarConfigs.successConfig;
    config[`data`] = {
      message: messageToDisplay
    } as SnackbarNotificationDataInterface;

    this.snackBar.openFromComponent(SnackbarNotificationComponent, config);
  }

  public openErrorSnackbar(messageToDisplay: string): void {
    const config = SnackbarConfigs.errorConfig;
    config[`data`] = {
      message: messageToDisplay
    } as SnackbarNotificationDataInterface;

    this.snackBar.openFromComponent(SnackbarNotificationComponent, config);
  }
}
