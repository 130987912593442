import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Patient } from 'src/app/data/models';

export function patientRequiredValidator(): ValidatorFn {
  const validator: ValidatorFn = (control: AbstractControl) => {

    const value = control.value;
    return value instanceof Patient || (value === null || value.trim().toLowerCase() === '') ? null : { required: true };
  };

  return validator;
}
