<ng-container *transloco="let t;">
  <h1 mat-dialog-title>{{t(title) | capitalize }}</h1>
  <div mat-dialog-content>
    <p>{{t(message) | capitalize }}</p>
    <mat-divider class="my-4"></mat-divider>
    <div class="row align-items-start">
      <div *ngFor="let conflict of conflicts" class="col-12">
        <h3 class="mb-1 text-primary font-weight-bold">{{conflict.appointment.startDate | date:'dd.MM.yyyy'}}
          ({{conflict.appointment.startDate | date:'HH:mm'}} -
          {{conflict.appointment.endDate | date:'HH:mm'}}) :</h3>
        <table matSort class="mb-4 w-100">
          <tr>
            <th class="pr-3">{{t('time-slot') | capitalize}}</th>
            <th class="px-3">{{t('therapy') | capitalize}}</th>
            <th class="px-3">{{t('room') | capitalize}}</th>
            <th class="px-3">{{t('users.name') | capitalize}}</th>
            <th class="px-3">{{t('patients.name') | capitalize}}</th>
            <th class="pl-3">{{t('reasons') | capitalize}}</th>
          </tr>
          <tr *ngFor="let c of conflict.conflicts" style="vertical-align: text-top;">
            <th class="pr-3">{{c.appointment.startDate | date:'dd.MM.yyyy'}} ({{c.appointment.startDate | date:'HH:mm'}}
              -
              {{c.appointment.endDate | date:'HH:mm'}})</th>
            <td class="px-3">
              {{c.appointment.therapy ? c.appointment.therapy.name : '-'}}
            </td>
            <td class="px-3">
              {{c.appointment.room ? c.appointment.room.name : '-'}}
            </td>
            <td class="pl-3">
              <ng-container *ngFor="let user of c.appointment.users">
                <span>{{user.getDisplayName()}}</span><br />
              </ng-container>
            </td>
            <td class="pl-3">
              <ng-container *ngFor="let patient of c.appointment.patients">
                <span>{{patient.getDisplayName()}}</span><br />
              </ng-container>
            </td>
            <td class="pl-3">
              <ng-container *ngIf="c.reasons; let reason">
                <ng-container *ngIf="reason.room">
                  <span
                    class="reason-title">{{t('therapy-blocks.confirmation.confirmation-conflict-same-room') | capitalize}}</span>
                  <p>{{reason.room.name}}</p>
                </ng-container>
                <ng-container *ngIf="reason.users && reason.users.length > 0">
                  <span
                    class="reason-title">{{t('therapy-blocks.confirmation.confirmation-conflict-same-users') | capitalize}}</span>
                  <p>
                    <ng-container *ngFor="let user of reason.users">
                      <span>{{user.getDisplayName()}}</span><br />
                    </ng-container>
                  </p>
                </ng-container>
                <ng-container *ngIf="reason.patients && reason.patients.length > 0">
                  <span
                    class="reason-title">{{t('therapy-blocks.confirmation.confirmation-conflict-same-patients') | capitalize}}</span>
                  <p>
                    <ng-container *ngFor="let patient of reason.patients">
                      <span>{{patient.getDisplayName()}}</span><br />
                    </ng-container>
                  </p>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <mat-divider class="mb-4"></mat-divider>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="cancel()">{{t('cancel') | capitalize }}</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>{{t('confirm') | capitalize }}</button>
    <a mat-button [routerLink]="['/view', 'conflicts']" target="_blank" class="ml-auto">
      <mat-icon>tab</mat-icon>
      <span class="ml-3">{{t('open-new-tab') | capitalize}}</span>
    </a>
  </div>
</ng-container>
