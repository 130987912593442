import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, Subject, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { convert } from 'src/app/core/validators';
import { BusinessGroup, Search, User } from 'src/app/data/models';
import { BusinessGroupsService, UsersService } from 'src/app/data/services';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;

  private subscriber = new Subject();

  public values: any[] = [[], []];

  constructor(private usersService: UsersService, private businessGroupsService: BusinessGroupsService) {}

  ngOnInit() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof User || value instanceof BusinessGroup) {
            return value;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        filter(
          (value: string | User | BusinessGroup) =>
            value instanceof User || value instanceof BusinessGroup || (value && value.length > 2)
        ),
        switchMap((value: string | User | BusinessGroup) => {
          if (value instanceof User || value instanceof BusinessGroup) {
            return of(value);
          } else if (value) {
            const v = convert(value);
            const search = new Search(v);

            search.params.push({ name: 'active', value: true });
            return zip(
              this.usersService.getUsersWithoutPagination(search).pipe(takeUntil(this.subscriber)),
              this.businessGroupsService.getBusinessGroupsWithoutPagination(search).pipe(takeUntil(this.subscriber))
            );
          }
        })
      )
      .subscribe((value: User | BusinessGroup | any[]) => {
        if (!(value instanceof User) && !(value instanceof BusinessGroup)) {
          this.values = value;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayItem(item: User | BusinessGroup): string {
    if (item && item instanceof User) {
      return item.getDisplayName();
    }

    if (item && item instanceof BusinessGroup) {
      return item.name;
    }

    return '';
  }
}
