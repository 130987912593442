<mat-form-field class="d-flex flex-column" *transloco="let t">
  <mat-label>{{ t('therapy') | capitalize }}</mat-label>
  <input matInput type="text" [formControl]="control" [matAutocomplete]="auto" (focus)="onfocusIn()" />
  <mat-error *ngIf="control.invalid">{{ t('validation.value-required') | capitalize }}</mat-error>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayTherapy">
    <mat-option *ngFor="let option of therapies" [value]="option">
      <img aria-hidden [style.backgroundColor]="option.color" height="25" width="25" class="mr-2" />
      <span [ngClass]="{ 'font-italic font-weight-lighter': !option.active }">{{ option.name }}</span>
      <span [ngClass]="{ 'font-italic font-weight-lighter': !option.active }" *ngIf="option.id >= 0">
        ({{ option.defaultDuration | date: 'HH:mm' }})</span
      >
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
