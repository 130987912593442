import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  DateTimeProvider,
  OAuthLogger,
  OAuthModuleConfig,
  OAuthNoopResourceServerErrorHandler,
  OAuthResourceServerErrorHandler,
  OAuthService,
  OAuthStorage,
  SystemDateTimeProvider,
  UrlHelperService
} from 'angular-oauth2-oidc';
import { SettingsInterface } from '../data/interfaces';
import { DefaultOAuthInterceptor, TwoFactorsOAuthInterceptor } from './interceptors';
import { AuthService, SettingsService } from './services';


export function createDefaultStorage(): Storage {
  return typeof sessionStorage !== 'undefined' ? sessionStorage : null;
}

export function createDefaultLogger() {
  return console;
}

export function initOAuth(settingsService: SettingsService): Promise<OAuthModuleConfig> {
  return new Promise((resolve, reject) => {
    settingsService.downloadSettings().then((settings: SettingsInterface) => {
      const config = {
        resourceServer: {
          allowedUrls: [settings.baseUrl],
          sendAccessToken: true
        }
      } as OAuthModuleConfig;

      resolve(config);
    }).catch(reject);
  });
}

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: []
})
export class OAuthCustomModule {
  static forRoot(): ModuleWithProviders<OAuthCustomModule> {
    return {
      ngModule: OAuthCustomModule,
      providers: [
        OAuthService,
        { provide: OAuthLogger, useFactory: createDefaultLogger },
        UrlHelperService,
        { provide: OAuthStorage, useFactory: createDefaultStorage },
        {
          provide: OAuthResourceServerErrorHandler,
          useClass: OAuthNoopResourceServerErrorHandler
        },
        {
          provide: DateTimeProvider,
          useClass: SystemDateTimeProvider
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TwoFactorsOAuthInterceptor,
          deps: [OAuthResourceServerErrorHandler, SettingsService, AuthService],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DefaultOAuthInterceptor,
          deps: [OAuthStorage, OAuthResourceServerErrorHandler, SettingsService],
          multi: true
        }
      ]
    };
  }
}
