import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { HttpParamsUtils, User, UserAdapter } from '../models';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: UserAdapter
  ) { }

  private getUsers(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `users/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getUsersWithPagination(pagination: PaginationInterface, search?: SearchInterface): Observable<PaginationResults<User>> {
    return this.getUsers(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const users: User[] = [];

        if (results.results) {
          for (const i of results.results) {
            users.push(this.adapter.adaptToObject(i));
          }
          results.results = users;

          return results as PaginationResults<User>;
        }
      })
    );
  }

  public getUsersWithoutPagination(search?: SearchInterface): Observable<User[]> {
    return this.getUsers(undefined, search).pipe(
      map((results: any[]) => {
        const users: User[] = [];

        results.forEach(elem => {
          users.push(this.adapter.adaptToObject(elem));
        });
        return users;
      })
    );
  }

  public getUser(id: number): Observable<User> {
    const url = this.settingsService.getSettings().baseUrl + `users/${id}/`;

    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public updateUser(user: User): Observable<User> {
    const url = this.settingsService.getSettings().baseUrl + `users/${user.id}/`;

    return this.httpClient.put(url, this.adapter.adaptToRequest(user)).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public setUserPassword(id: number, newPassword: string): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `users/${id}/change_password/`;
    return this.httpClient.post(url, { password: newPassword }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public resetUserPassword(userEmail: string, redirectUrl: string): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `password_reset/`;
    return this.httpClient.post(url, { email: userEmail, url: redirectUrl }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public confirmResetUserPassword(userToken: string, newPassword: string): Observable<User> {
    const url = this.settingsService.getSettings().baseUrl + `password_reset/confirm/`;
    return this.httpClient.post(url, { token: userToken, password: newPassword }).pipe(
      map((response: any) => {
        return this.adapter.adaptToObject(response);
      })
    );
  }

  public sendCodeVerificationRequest(u: string, p: string): Observable<any> {
    const url = this.settingsService.getSettings().baseMediaURL + `/o/mfa-login`;
    return this.httpClient.post(url, { username: u, password: p }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
