<mat-form-field class="d-flex flex-column" *transloco="let t;">
  <mat-label>{{t('patient') | capitalize}}</mat-label>
  <input matInput type="text" [formControl]="control" [matAutocomplete]="auto" />
  <mat-error *ngIf="control.invalid">{{t('validation.value-required') | capitalize}}</mat-error>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayPatient">
    <mat-option *ngFor="let option of patients" [value]="option" class="patients-autocomplete">
      <span>{{option.getDisplayName()}}</span><br/>
      <span *ngIf="option.birthdate" class="text-muted"> ({{ option.birthdate | date: 'dd.MM.yyyy' }})</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
