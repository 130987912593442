import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dimensionRequiredValidator<T>(constructor: new () => T): ValidatorFn {
  const validator: ValidatorFn = (control: AbstractControl) => {

    const value = control.value;
    return value instanceof constructor || (value === null || value.trim().toLowerCase() === '') ? null : { required: true };
  };

  return validator;
}
