import { Injectable } from '@angular/core';
import { Adapter, ExternalEntityInterface, HttpEntityInterface } from '../interfaces';
import { UserAppointmentState } from './appointment-state';
import { Group, GroupAdapter } from './group';

export class User implements HttpEntityInterface, ExternalEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public email: string,
    public firstName: string,
    public lastName: string,

    public externalId?: number,
    public username?: string,
    public birthdate?: string,
    public isActive?: boolean,
    public isExternalActive?: boolean,
    public isStaff?: boolean,
    public isSuperUser?: boolean,
    public phone?: string,
    public mobile?: string,
    public address?: string,
    public color?: string,
    public comment?: string,
    public groups?: Group[],
    public state?: UserAppointmentState,
    public businessGroups?: number[]
  ) {}

  public getDisplayName(): string {
    return `${this.lastName && this.lastName.toUpperCase()} ${this.firstName}`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserAdapter implements Adapter<User> {
  constructor(public adapter: GroupAdapter) {}

  public adaptToObject(item: any): User {
    const user = new User(item.id, item.url, item.email, item.first_name, item.last_name);

    user.externalId = item && item.external_id;
    user.username = item && item.username;
    user.birthdate = item && item.birthdate;
    user.isActive = item && item.is_active;
    user.isExternalActive = item && item.external_is_active;
    user.isStaff = item && item.is_staff;
    user.isSuperUser = item && item.is_superuser;
    user.phone = item && item.phone;
    user.mobile = item && item.mobile;
    user.address = item && item.contact_address;
    user.color = item && item.color;
    user.comment = item && item.comment;

    if (item && item.groups) {
      const groups: Group[] = [];
      item.groups.forEach((element: any) => groups.push(this.adapter.adaptToObject(element)));
      user.groups = groups;
    }

    if (item && item.business_groups) {
      const groups: number[] = [];
      groups.push(...item.business_groups.map((element: any) => element.id));
      user.businessGroups = groups;
    }

    return user;
  }
  public adaptToRequest(item: User): any {
    const object = {};

    if (item.id) {
      object[`id`] = item.id;
    }
    if (item.username) {
      object[`username`] = item.username;
    }
    if (item.email) {
      object[`email`] = item.email;
    }
    if (item.firstName) {
      object[`first_name`] = item.firstName;
    }
    if (item.lastName) {
      object[`last_name`] = item.lastName;
    }
    if (item.phone) {
      object[`phone`] = item.phone;
    }
    if (item.mobile) {
      object[`mobile`] = item.mobile;
    }
    if (item.address) {
      object[`contact_address`] = item.address;
    }
    if (item.color) {
      object[`color`] = item.color;
    }
    if (item.comment) {
      object[`comment`] = item.comment;
    }
    if (item.isActive !== undefined || item.isActive !== null) {
      object[`is_active`] = item.isActive;
    }

    object[`groups`] = [];
    item.groups.forEach((group: Group) => {
      object[`groups`].push(this.adapter.adaptToRequest(group));
    });

    object[`business_groups`] = [];
    item.businessGroups.forEach((group: number) => {
      object[`business_groups`].push(group);
    });

    return object;
  }
}
