import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { StorageKey, StorageService } from 'src/app/core/services';
import { BusinessGroup, Goal, Office, Patient, Room, Search, Therapy, User } from 'src/app/data/models';
import { RoomsService } from 'src/app/data/services';

export interface CalendarFiltersSelectedData {
  users: User[];
  patients: Patient[];
  therapies: Therapy[];
  rooms: Room[];
  offices: Office[];
  goals: Goal[];
}

@Component({
  selector: 'app-calendar-filters',
  templateUrl: 'calendar-filters.component.html',
  styleUrls: ['./calendar-filters.component.scss'],
})
export class CalendarFiltersComponent implements OnInit {
  @Input() control: FormGroup;
  @Input() storageKey: StorageKey;
  @Input() syncOfficesAndRooms: boolean = false;
  @Input() keepOfficesInSearches: boolean = true;

  @Output() selectedFiltersEmitter: EventEmitter<CalendarFiltersSelectedData> = new EventEmitter();

  private subscriber = new Subject();

  private user: User;

  public selectedFilters: CalendarFiltersSelectedData = {
    users: [],
    patients: [],
    rooms: [],
    therapies: [],
    offices: [],
    goals: [],
  };

  constructor(
    protected route: ActivatedRoute,
    private storageService: StorageService,
    private roomService: RoomsService
  ) {}

  public ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.subscriber)).subscribe((data: Data) => {
      if (data && data.user) {
        this.user = data.user;
        this.initFormListeners();
      }
    });
  }

  private initFormListeners(): void {
    if (localStorage.getItem(this.storageKey + this.user.id)) {
      this.initFiltersFromStorage();
    } else {
      this.selectedFilters.users.push(this.user);
    }

    this.control
      .get('therapy')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Therapy | string) => {
        if (value instanceof Therapy) {
          this.control.get('therapy').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.therapies.some((v: Therapy) => v.id === value.id)) {
            this.selectedFilters.therapies.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('office')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Office | string) => {
        if (value instanceof Office) {
          this.control.get('office').patchValue('', { emitEvent: false, onlySelf: true });

          if (this.keepOfficesInSearches) {
            if (!this.selectedFilters.offices.some((v: Office) => v.id === value.id)) {
              this.selectedFilters.offices.push(value);
            }
          }

          if (this.syncOfficesAndRooms) {
            const search = new Search();
            search.params = [
              {
                name: 'office',
                value: value.id,
              },
            ];
            this.roomService
              .getRoomsWithoutPagination(search)
              .pipe(take(1))
              .subscribe((rooms: Room[]) => {
                rooms.forEach((room: Room) => {
                  if (!this.selectedFilters.rooms.some((r: Room) => r.id === room.id)) {
                    this.selectedFilters.rooms.push(room);
                  }
                });

                this.emitData();
              });
          } else {
            this.emitData();
          }
        }
      });

    this.control
      .get('room')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Room | string) => {
        if (value instanceof Room) {
          this.control.get('room').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.rooms.some((v: Room) => v.id === value.id)) {
            this.selectedFilters.rooms.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('user')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: User | BusinessGroup | string) => {
        if (value instanceof User || value instanceof BusinessGroup) {
          this.control.get('user').patchValue('', { emitEvent: false, onlySelf: true });

          if (value instanceof User) {
            if (!this.selectedFilters.users.some((v: User) => v.id === value.id)) {
              this.selectedFilters.users.push(value);
            }
          }

          if (value instanceof BusinessGroup) {
            const values = value.users.filter(
              (user) => !this.selectedFilters.users.some((u: User) => user.id === u.id)
            );
            this.selectedFilters.users.push(...values);
          }

          this.emitData();
        }
      });

    this.control
      .get('patient')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Patient | string) => {
        if (value instanceof Patient) {
          this.control.get('patient').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.patients.some((v: Patient) => v.id === value.id)) {
            this.selectedFilters.patients.push(value);
            this.emitData();
          }
        }
      });

    this.control
      .get('goal')
      .valueChanges.pipe(takeUntil(this.subscriber))
      .subscribe((value: Goal | string) => {
        if (value instanceof Goal) {
          this.control.get('goal').patchValue('', { emitEvent: false, onlySelf: true });

          if (!this.selectedFilters.goals.some((v: Goal) => v.id === value.id)) {
            this.selectedFilters.goals.push(value);
            this.emitData();
          }
        }
      });

    this.emitData();
  }

  public removeGoalFromFilters(value: Goal): void {
    const index = this.selectedFilters.goals.findIndex((v: Goal) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.goals.splice(index, 1);
      this.emitData();
    }
  }

  public removePatientFromFilters(value: Patient): void {
    const index = this.selectedFilters.patients.findIndex((v: Patient) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.patients.splice(index, 1);
      this.emitData();
    }
  }

  public removeUserFromFilters(value: User): void {
    const index = this.selectedFilters.users.findIndex((v: User) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.users.splice(index, 1);
      this.emitData();
    }
  }

  public removeTherapyFromFilters(value: Therapy): void {
    const index = this.selectedFilters.therapies.findIndex((v: Therapy) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.therapies.splice(index, 1);
      this.emitData();
    }
  }

  public removeRoomFromFilters(value: Room): void {
    const index = this.selectedFilters.rooms.findIndex((v: Room) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.rooms.splice(index, 1);
      this.emitData();
    }
  }

  public removeOfficeFromFilters(value: Office): void {
    const index = this.selectedFilters.offices.findIndex((v: Office) => v.id === value.id);
    if (index >= 0) {
      this.selectedFilters.offices.splice(index, 1);
      this.emitData();
    }
  }

  public trackByModelId(index: number, name: any): number {
    return name.id;
  }

  private emitData(): void {
    this.storageService.storeFilters(this.storageKey, this.user, this.selectedFilters);
    this.selectedFiltersEmitter.emit(this.selectedFilters);
  }

  private initFiltersFromStorage(): void {
    const lastFilters: CalendarFiltersSelectedData = this.storageService.loadFilters(this.storageKey, this.user);

    this.selectedFilters = lastFilters;
  }
}
