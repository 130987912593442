<router-outlet></router-outlet>
<div id="overlay" [hidden]="!loading">
  <div>
    <img
      [src]="logoUrl"
      onerror="this.src = 'assets/images/logo.svg'"
      alt="logo"
      srcset=""
      style="width: 17rem; height: auto"
      class="p-4"
    />
  </div>
  <br />
  <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>
