import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { PaginationInterface, PaginationResults, SearchInterface } from '../interfaces';
import { HttpParamsUtils, Patient, PatientAdapter } from '../models';

@Injectable({ providedIn: 'root' })
export class PatientsService {
  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: PatientAdapter
  ) { }

  private getPatients(pagination?: PaginationInterface, search?: SearchInterface): Observable<any> {
    const url = this.settingsService.getSettings().baseUrl + `patients/`;

    const paginationParams = pagination && pagination.toPaginationParams();
    const searchParams = search && search.toPaginationParams();

    const httpParams = HttpParamsUtils.getHttpParamsFromPaginationParams(paginationParams, searchParams);

    return this.httpClient.get(url, { params: httpParams });
  }

  public getPatientsWithPagination(pagination: PaginationInterface, search?: SearchInterface): Observable<PaginationResults<Patient>> {
    return this.getPatients(pagination, search).pipe(
      map((results: PaginationResults<any>) => {
        const patients: Patient[] = [];

        if (results.results) {
          for (const i of results.results) {
            patients.push(this.adapter.adaptToObject(i));
          }
          results.results = patients;

          return results as PaginationResults<Patient>;
        }
      })
    );
  }

  public getPatientsWithoutPagination(search?: SearchInterface): Observable<Patient[]> {
    return this.getPatients(undefined, search).pipe(
      map((results: any[]) => {
        const patients: Patient[] = [];

        results.forEach(elem => {
          patients.push(this.adapter.adaptToObject(elem));
        });
        return patients;
      })
    );
  }

  public getPatientExternalUri(patient: Patient): Observable<string> {
    const url = this.settingsService.getSettings().baseUrl + `patients/${patient.id}/external_uri/`;
    return this.httpClient.get(url, {}).pipe(
      map((response: any) => {
        return response.url;
      })
    );
  }

  public get(patientId: number | string): Observable<Patient> {
    const url = this.settingsService.getSettings().baseUrl + `patients/?external_id=${patientId}`;
    return this.httpClient.get<Patient>(url).pipe(
      map((result: any) => {
        const patient = this.adapter.adaptToObject(result[0]);
        return patient;
      })
    );
  }
}
