import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AsyncSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { SettingsInterface } from 'src/app/data/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private httpClient: HttpClient,
    private translocoService: TranslocoService
  ) { }

  /*
  * Contain the webroot with a trailing slash (eg. http://application.com/my/folder/)
  */
  public static webroot: string;

  /*
  * Contain the full webroot (not sanitized) without a trailing slash (eg. http://application.com/app_dev.php/my/folder)
  */
  public static fullWebroot: string;

  private settings: AsyncSubject<SettingsInterface> = new AsyncSubject();
  private storedSettings: SettingsInterface;
  private initialized = false;

  public getSettings(): SettingsInterface {
    return this.storedSettings;
  }

  public downloadSettings(): Promise<SettingsInterface> {
    if (!this.initialized) {
      this.initialized = true;
      const url = environment.settings_url;

      this.settings.subscribe((settings: SettingsInterface) => {
        this.storedSettings = settings;
        SettingsService.fullWebroot = settings.baseUrl;
      });

      this.httpClient.get(url).subscribe((response: any) => {
        this.settings.next(response as SettingsInterface);
        this.settings.complete();

      }, err => {
        this.settings.error(err);
      });
    }

    return this.settings.pipe(share()).toPromise();
  }

  public getLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  public setLanguage(language: string): void {
    this.translocoService.setActiveLang(language);
  }
}
