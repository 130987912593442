import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces';
import { Appointment, AppointmentAdapter } from './appointment';
import { Patient, PatientAdapter } from './patient';
import { Room, RoomAdapter } from './room';
import { User, UserAdapter } from './user';

export interface ConflictDetail {
  appointment: Appointment;
  reasons: ConflictReason;
}

export interface ConflictReason {
  room?: Room;
  users?: User[];
  patients?: Patient[];
}

export class Conflict {
  constructor(
    public appointment: Appointment,
    public conflicts?: ConflictDetail[]
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class ConflictAdapter implements Adapter<Conflict> {
  constructor(
    private adapter: AppointmentAdapter,
    private roomAdapter: RoomAdapter,
    private userAdapter: UserAdapter,
    private patientAdapter: PatientAdapter
  ) { }

  public adaptToObject(item: any): Conflict {
    const conflicts: ConflictDetail[] = [];

    if (item && item.appointments_in_conflict) {
      item.appointments_in_conflict.forEach((c: any) => {
        const itemAppointment = this.adapter.adaptToObject(c.appointment);
        const reason: ConflictReason = {
          room: undefined,
          users: [],
          patients: []
        };
        if (c && c.reasons) {
          if (c.reasons.room) {
            reason.room = this.roomAdapter.adaptToObject(c.reasons.room);
          }

          if (c.reasons.collaborators && c.reasons.collaborators.length > 0) {
            c.reasons.collaborators.forEach(v =>
              reason.users.push(this.userAdapter.adaptToObject(v)));
          }

          if (c.reasons.patients && c.reasons.patients.length > 0) {
            c.reasons.patients.forEach(v =>
              reason.patients.push(this.patientAdapter.adaptToObject(v)));
          }
        }

        conflicts.push({ appointment: itemAppointment, reasons: reason });
      });
    }

    const appointment = this.adapter.adaptToObject(item.appointment);

    const conflict = new Conflict(
      appointment,
      conflicts,
    );

    return conflict;
  }

  public adaptToRequest(item: Conflict): any {
    return {};
  }
}
