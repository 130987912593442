import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { PaginationParam } from 'src/app/data/interfaces';
import { Office, Search } from 'src/app/data/models';
import { OfficesService } from 'src/app/data/services';

@Component({
  selector: 'app-office-select',
  templateUrl: './office-select.component.html',
  styleUrls: ['./office-select.component.scss'],
})
export class OfficeSelectComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() params: PaginationParam[] = [];

  private subscriber = new Subject();

  public offices: Office[];

  constructor(private officesService: OfficesService) {}

  ngOnInit() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.subscriber),
        debounceTime(250),
        distinctUntilChanged(),
        map((value: any) => {
          if (value instanceof Office) {
            return value as Office;
          } else {
            const final = value.trim().toLowerCase();
            return final !== '' ? final : undefined;
          }
        }),
        switchMap((filter: string | Office) => {
          if (filter instanceof Office) {
            return of(filter);
          } else {
            const search = new Search(filter ? filter : '', this.params);
            return this.officesService.getOfficesWithoutPagination(search).pipe(takeUntil(this.subscriber));
          }
        })
      )
      .subscribe((value: Office[] | Office) => {
        if (!(value instanceof Office)) {
          this.offices = value;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public displayOffice(office: Office): string {
    return office && office.name ? office.name : '';
  }
}
