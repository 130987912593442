import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Goal, Office, Patient, Room, Therapy, User } from 'src/app/data/models';
import { CalendarFiltersSelectedData } from 'src/app/shared/components/calendar-filters/calendar-filters.component';
import { SearchFiltersSelectedData } from 'src/app/shared/components/search-filters/search-filters.component';

export enum StorageKey {
  SEARCH_COMPONENT = 'search_',
  CALENDAR_COMPONENT = 'calendar_',
  PLANNER_COMPONENT = 'planner_',
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor() {}

  public storeFilters(
    key: StorageKey,
    user: User,
    filters: CalendarFiltersSelectedData | SearchFiltersSelectedData
  ): void {
    localStorage.setItem(key.toString() + user.id, JSON.stringify(filters));
  }

  public loadFilters(key: StorageKey, user: User): CalendarFiltersSelectedData | SearchFiltersSelectedData {
    const result: CalendarFiltersSelectedData | SearchFiltersSelectedData = {
      users: [],
      offices: [],
      patients: [],
      rooms: [],
      therapies: [],
      goals: [],
    };

    const lastFilters = JSON.parse(localStorage.getItem(key.toString() + user.id));

    (lastFilters.users as User[]).forEach((u) =>
      result.users.push(new User(u.id, u.url, u.email, u.firstName, u.lastName))
    );

    (lastFilters.patients as Patient[]).forEach((p) =>
      result.patients.push(
        new Patient(
          p.id,
          p.url,
          p.firstName,
          p.lastName,
          p.gender,
          p.needTranslator,
          moment(p.createdAt),
          moment(p.modifiedAt),
          p.active
        )
      )
    );

    (lastFilters.therapies as Therapy[]).forEach((t) =>
      result.therapies.push(
        new Therapy(t.id, t.url, t.name, moment(t.defaultDuration), t.color, moment(t.createdAt), moment(t.modifiedAt))
      )
    );

    (lastFilters.offices as Office[]).forEach((o) =>
      result.offices.push(new Office(o.id, o.url, o.name, o.location, moment(o.createdAt), moment(o.modifiedAt)))
    );

    (lastFilters.goals as Goal[]).forEach((o) =>
      result.goals.push(
        new Goal(o.id, o.url, o.name, o.active, o.defaultDuration, moment(o.createdAt), moment(o.modifiedAt))
      )
    );

    (lastFilters.rooms as Room[]).forEach((r) =>
      result.rooms.push(
        new Room(
          r.id,
          r.url,
          r.name,
          moment(r.createdAt),
          moment(r.modifiedAt),
          undefined,
          undefined,
          r.office as Office
        )
      )
    );

    if (key === StorageKey.SEARCH_COMPONENT) {
      result['free'] = lastFilters.free;
      result['action'] = lastFilters.action;
      result['personal'] = lastFilters.personal;
      result['no_show'] = lastFilters.no_show;
      result['flag_action'] = lastFilters.flag_action;
      result['startDate'] = lastFilters.startDate ? new Date(lastFilters.startDate) : null;
      result['endDate'] = lastFilters.endDate ? new Date(lastFilters.endDate) : null;
    }

    return result;
  }
}
