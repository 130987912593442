import { FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function startBeforeEndValidator(): ValidatorFn {
  const validator: ValidatorFn = (group: FormGroup) => {
    const startTime = moment(group.get('startTime').value, [moment.ISO_8601, 'HH:mm']);
    const endTime = moment(group.get('endTime').value, [moment.ISO_8601, 'HH:mm']);

    return startTime.isBefore(endTime) ? null : { startBeforeEnd: true };
  };

  return validator;
}
