import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CalendarEvent } from 'angular-calendar';
import { SettingsService } from 'src/app/core/services';
import {
  Appointment,
  AppointmentAdapter,
  AppointmentVisibility,
  Group,
  Patient,
  PatientAppointmentStateEnum,
  User,
} from '../models';

export enum ErrorCode {
  OUT_OF_SYNC = 'appointment-out-of-sync',
  CONFLICT = 'appointments-conflicts',
}

@Injectable({ providedIn: 'root' })
export class BusinessService {
  constructor(
    private settingsService: SettingsService,
    private appointmentAdapter: AppointmentAdapter,
    private translocoService: TranslocoService
  ) {}

  public isAppointmentUpdatable(user: User, appointment: Appointment): boolean {
    switch (appointment.visibility) {
      case AppointmentVisibility.PUBLIC:
      case AppointmentVisibility.INTERN:
        return true;
      case AppointmentVisibility.PRIVATE:
        return user.isSuperUser || appointment.users && appointment.users.some((u) => u.id === user.id);
      default:
        return false;
    }
  }

  public isActionNeededForAppointment(appointment: Appointment): boolean {
    if (appointment.therapy && (!appointment.users || appointment.users.length === 0)) {
      return true;
    }

    if (appointment.registrations && appointment.registrations.length > 0) {
      return true;
    }

    return false;
  }

  public isAppointmentFreeForPatient(appointment: Appointment): boolean {
    if (appointment.visibility === AppointmentVisibility.PRIVATE) {
      return false;
    }
    if (appointment.markOccupied) {
      return false;
    }

    const active =
      appointment.patients && appointment.patients.length > 0
        ? appointment.patients.filter((p) => p.state && p.state.state !== PatientAppointmentStateEnum.CANCELED)
        : [];

    return active.length === 0;
  }

  public isPatientCancelableInAppointment(patient: Patient): boolean {
    return patient.state && patient.state.state === PatientAppointmentStateEnum.REGISTERED;
  }

  public isPatientConfirmedInAppointment(patient: Patient): boolean {
    return patient.state && patient.state.state !== PatientAppointmentStateEnum.CANCELED;
  }

  public canPatientChangeToStateInAppointment(patient: Patient, state: PatientAppointmentStateEnum): boolean {
    if (!patient || !patient.state || !patient.state.state) {
      return false;
    }

    const patientState = patient.state.state;

    switch (state) {
      case PatientAppointmentStateEnum.REGISTERED:
        return (
          patientState === PatientAppointmentStateEnum.CANCELED ||
          patientState === PatientAppointmentStateEnum.NO_SHOW ||
          patientState === PatientAppointmentStateEnum.WAITING ||
          patientState === PatientAppointmentStateEnum.DONE ||
          patientState === PatientAppointmentStateEnum.TO_PLAN
        );
      case PatientAppointmentStateEnum.DONE:
      case PatientAppointmentStateEnum.CANCELED:
      case PatientAppointmentStateEnum.NO_SHOW:
      case PatientAppointmentStateEnum.TO_PLAN:
        return patientState === PatientAppointmentStateEnum.REGISTERED;
      default:
        return false;
    }
  }

  public getAppointmentColorInCalendar(appointment: Appointment): string {
    if (appointment.color) {
      return appointment.color;
    }

    if (appointment.therapy && appointment.therapy.color) {
      return appointment.therapy.color;
    }

    return this.settingsService.getSettings().calendar.defaultEventColor;
  }

  public getPatientStateColor(patient: Patient): string {
    if (!patient) {
      return undefined;
    }

    if (!patient.state || !patient.state.state) {
      return undefined;
    }

    switch (patient.state.state) {
      case PatientAppointmentStateEnum.DONE:
        return 'primary';
      case PatientAppointmentStateEnum.CANCELED:
        return 'accent';
      case PatientAppointmentStateEnum.NO_SHOW:
        return 'warn';
      case PatientAppointmentStateEnum.TO_PLAN:
        return 'to-plan';
      default:
        return undefined;
    }
  }

  public previousPatientStateInAppointment(patient: Patient): PatientAppointmentStateEnum {
    if (patient && patient.state) {
      switch (patient.state.state) {
        case PatientAppointmentStateEnum.WAITING:
        case PatientAppointmentStateEnum.REGISTERED:
        case PatientAppointmentStateEnum.NO_SHOW:
        case PatientAppointmentStateEnum.CANCELED:
        case PatientAppointmentStateEnum.DONE:
          return PatientAppointmentStateEnum.REGISTERED;
        default:
          return PatientAppointmentStateEnum.REGISTERED;
      }
    }
  }

  public getCalendarEventFromAppointment(appointment: any): CalendarEvent<Appointment> {
    const a = appointment instanceof Appointment ? appointment : this.appointmentAdapter.adaptToObject(appointment);

    return {
      start: a.startDate.toDate(),
      end: a.endDate.toDate(),
      title: '',
      color: {
        primary: this.getAppointmentColorInCalendar(a),
        secondary: this.getAppointmentColorInCalendar(a),
      },
      actions: [],
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      draggable: false,
      cssClass: 'my-custom-class',
      meta: a,
    } as CalendarEvent<Appointment>;
  }

  public getErrorMessageFromAppointmentUpdate(error: any, isBulkEdit: boolean): any {
    let result = 'notification.operation_error';
    if (error && error.code) {
      switch (error.code) {
        case ErrorCode.OUT_OF_SYNC:
          if (isBulkEdit) {
            result = 'notification.appointments_out_of_sync';
          } else {
            const appointment = this.appointmentAdapter.adaptToObject(error.appointment);
            result = this.translocoService.translate<string>('notification.appointment_out_of_sync', {
              date: appointment.modifiedAt.format('DD.MM.YYYY - HH:mm'),
              user: appointment.modifiedBy.getDisplayName(),
            });
          }
          break;
      }
    }
    return result;
  }
}
