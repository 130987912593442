import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services';

@Component({
  selector: 'app-no-permissions',
  templateUrl: 'nopermissions-layout.components.html',
  styleUrls: ['./nopermissions-layout.components.scss'],
})
export class NoPermissionsLayoutComponent implements OnInit {
  public logoUrl: string;

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.logoUrl = this.settingsService.getSettings().logoUrl;
  }
}
