import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import {
  officeRequiredValidator,
  oneDimensionRequiredValidator,
  roomRequiredValidator,
  therapyRequiredValidator,
  userRequiredValidator,
} from 'src/app/core/validators';
import { PaginationParam } from 'src/app/data/interfaces';
import { AppointmentVisibility, Office, Room, Therapy, User } from 'src/app/data/models';
import { CapitalizePipe } from '../../pipes';

export enum BulkAction {
  DELETE,
  UPDATE,
}

@Component({
  selector: 'app-appointment-form-bulk',
  templateUrl: './appointment-form-bulk.component.html',
  styleUrls: ['./appointment-form-bulk.component.scss'],
})
export class AppointmentFormBulkComponent implements OnInit, OnDestroy {
  @Output() bulkData: EventEmitter<any> = new EventEmitter();

  private unsubscribe: Subject<void> = new Subject();

  private defaultTherapy: Therapy;
  private defaultOffice: Office;
  private defaultRoom: Room;
  private defaultUser: User;
  private defaultVisibility = -1;

  public appointmentForm: FormGroup;
  public color: string;

  public loading = false;

  json = JSON;
  appointmentVisibility = AppointmentVisibility;

  public activeDataParams: PaginationParam[] = [{ name: 'active', value: true }];

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private translocoService: TranslocoService,
    private capitalizePipe: CapitalizePipe
  ) {}

  ngOnInit(): void {
    this.translocoService.selectTranslate('actual-value').subscribe((value: string) => {
      this.defaultTherapy = new Therapy(
        -1,
        undefined,
        this.capitalizePipe.transform(value),
        undefined,
        undefined,
        undefined,
        undefined
      );
      this.defaultOffice = new Office(
        -1,
        undefined,
        this.capitalizePipe.transform(value),
        undefined,
        undefined,
        undefined
      );
      this.defaultRoom = new Room(-1, undefined, this.capitalizePipe.transform(value), undefined, undefined);
      this.defaultUser = new User(-1, undefined, undefined, this.capitalizePipe.transform(value), '');

      this.initForm();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public clearAll(): void {
    this.clearForm();
    this.clearUsers();
    this.clearComplementary();
  }

  public clearForm(): void {
    this.appointmentForm.patchValue(
      {
        visibility: this.defaultVisibility,
        therapy: this.defaultTherapy,
        office: this.defaultOffice,
        room: this.defaultRoom,
      },
      { emitEvent: false }
    );

    this.appointmentForm.markAsUntouched();
  }

  public clearUsers(): void {
    this.usersArray.clear();
    this.usersArray.push(new FormControl(this.defaultUser, userRequiredValidator()));
  }

  public clearComplementary(): void {
    this.color = this.settingsService.getSettings().calendar.defaultEventColor;
    this.appointmentForm.patchValue({ comment: '' }, { emitEvent: false });
  }

  public save(): void {
    if (this.appointmentForm.valid) {
      this.loading = true;

      const visibility = this.appointmentForm.get('visibility').value;
      const therapy = this.appointmentForm.get('therapy').value;
      const office = this.appointmentForm.get('office').value;
      const room = this.appointmentForm.get('room').value;
      const comment = this.appointmentForm.get('comment').value;
      const notification = this.appointmentForm.get('withNotification').value;

      const users: User[] = [];
      this.usersArray.controls.forEach((control: FormControl) => {
        if (control.value instanceof User) {
          users.push(control.value as User);
        }
      });

      const bulk: any = { _action: BulkAction.UPDATE };
      bulk.notification = notification;

      if (visibility && visibility !== -1) {
        bulk.visibility = visibility;
      }

      if (!therapy || therapy === '') {
        bulk.therapy = null;
      } else if (therapy instanceof Therapy) {
        if (therapy.id >= 0) {
          bulk.therapy = therapy.id;
        }
      }

      if (!office || office === '') {
        bulk.office = null;
      } else if (office instanceof Office) {
        if (office.id >= 0) {
          bulk.office = office.id;
        }
      }

      if (!room || room === '') {
        bulk.room = null;
      } else if (room instanceof Room) {
        if (room.id >= 0) {
          bulk.room = room.id;
        }
      }

      if (users.length === 0) {
        bulk.collaborators = [];
      } else if (!users.some((u) => u.id < 0)) {
        bulk.collaborators = [];
        users.forEach((u) => bulk.collaborators.push(u.id));
      }

      if (comment && comment.trim() !== '') {
        bulk.comment = comment;
      }

      if (this.color !== this.settingsService.getSettings().calendar.defaultEventColor) {
        bulk.color = this.color;
      }

      bulk.appointments = [];

      this.bulkData.emit(bulk);

      this.loading = false;
    }
  }

  public delete(): void {
    this.loading = true;

    const notification = this.appointmentForm.get('withNotification').value;

    const bulk: any = { _action: BulkAction.DELETE };
    bulk.notification = notification;
    bulk.appointments = [];

    this.bulkData.emit(bulk);
    
    this.loading = false;
  }

  public canDisplayVisibility(v: AppointmentVisibility): boolean {
    return v !== AppointmentVisibility.PRIVATE;
  }

  private initForm(): void {
    this.appointmentForm = this.fb.group({
      visibility: [this.defaultVisibility, Validators.required],
      therapy: [this.defaultTherapy, [therapyRequiredValidator()]],
      office: [this.defaultOffice, [officeRequiredValidator()]],
      room: [this.defaultRoom, [roomRequiredValidator()]],
      users: this.fb.array([new FormControl(this.defaultUser, userRequiredValidator())]),
      comment: [''],
      withNotification: [false, [Validators.required]],
    });

    this.color = this.settingsService.getSettings().calendar.defaultEventColor;

    this.appointmentForm.setValidators(oneDimensionRequiredValidator());

    this.appointmentForm
      .get('therapy')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((value: any) => {
        if (value && value instanceof Therapy) {
          if (value.color) {
            this.color = value.color;
          }
        }
      });

    this.appointmentForm
      .get('office')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((value: Office) => {
        if (!value || value instanceof Office) {
          if (value instanceof Office && value.id < 0) {
            this.appointmentForm.patchValue({ room: this.defaultRoom }, { emitEvent: false, onlySelf: false });
            this.appointmentForm.updateValueAndValidity();
          } else {
            this.appointmentForm.patchValue({ room: '' }, { emitEvent: false, onlySelf: false });
            this.appointmentForm.updateValueAndValidity();
          }
        }
      });

    this.appointmentForm
      .get('room')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((value: Room) => {
        if (value && value instanceof Room) {
          if (value.id < 0) {
            this.appointmentForm.patchValue({ office: this.defaultOffice }, { emitEvent: false, onlySelf: false });
            this.appointmentForm.updateValueAndValidity();
          } else {
            this.appointmentForm.patchValue(
              { office: value.office ? value.office : '' },
              { emitEvent: false, onlySelf: false }
            );
            this.appointmentForm.updateValueAndValidity();
          }
        }
      });

    this.usersArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((users: User[]) => {
      if (users.some((u) => u.id < 0) && users.length > 1) {
        this.usersArray.removeAt(0);
      }
    });
  }

  get usersArray() {
    return this.appointmentForm.get('users') as FormArray;
  }

  get presetColors(): string[] {
    return this.settingsService.getSettings().calendar.presetColors;
  }
}
