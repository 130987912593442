import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter, ExternalEntityInterface, HttpEntityInterface } from '../interfaces';
import { Office, OfficeAdapter } from './office';
import { User, UserAdapter } from './user';

export class Room implements HttpEntityInterface, ExternalEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public name: string,
    public createdAt: moment.Moment,
    public modifiedAt: moment.Moment,

    public externalId?: number,
    public description?: string,
    public office?: Office,
    public createdBy?: User,
    public modifiedBy?: User,
    public deletedAt?: moment.Moment,
    public deletedBy?: User,

    public active?: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class RoomAdapter implements Adapter<Room> {
  constructor(private adapter: UserAdapter, private officeAdapter: OfficeAdapter) {}

  public adaptToObject(item: any): Room {
    const room = new Room(
      item.id,
      item.url,
      item.name,
      moment.tz(item.created_at, 'UTC'),
      moment.tz(item.modified_at, 'UTC')
    );

    room.externalId = item && item.external_id;
    room.description = item && item.description;
    room.deletedAt = item && moment.tz(item.deleted_at, 'UTC');

    if (item && item.created_by) {
      room.createdBy = this.adapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      room.modifiedBy = this.adapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      room.deletedBy = this.adapter.adaptToObject(item.deleted_by);
    }

    if (item && item.office) {
      room.office = this.officeAdapter.adaptToObject(item.office);
    }

    if (item && item.active !== null && item.active !== undefined) {
      room.active = item.active;
    }

    return room;
  }
  public adaptToRequest(item: Room): any {
    const object = {
      name: item.name,
      active: item.active,
    };

    if (item.id) {
      object[`id`] = item.id;
    }

    return object;
  }
}
