import { SettingsInterface } from '../data/interfaces';
import { SettingsService } from './services';

export class Boot {
  static bootSettings(settingsService: SettingsService) {
    return (): Promise<SettingsInterface> => {
      return settingsService.downloadSettings();
    };
  }
}
