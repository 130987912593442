import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SettingsInterface } from 'src/app/data/interfaces';
import { AuthService, SettingsService } from '../services';

@Injectable()
export class TwoFactorsOAuthInterceptor implements HttpInterceptor {
    constructor(
        private errorHandler: OAuthResourceServerErrorHandler,
        private settingsService: SettingsService,
        private authService: AuthService
    ) { }

    protected checkUrl(allowedUrls: string[], url: string): boolean {
        const found = allowedUrls.find(u =>
            url.startsWith(u)
        );
        return !!found;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url.toLowerCase();

        if (!this.settingsService.getSettings()) {
            return next.handle(req);
        }

        return from(this.settingsService.downloadSettings()).pipe(
            switchMap((settings: SettingsInterface) => {
                if (!settings.twoFactorsAuthenticationEnabled) {
                    return next.handle(req);
                }

                if (!url.includes('/o/token/')) {
                    return next.handle(req);
                }

                if (this.authService.challange && this.authService.code) {
                    req = req.clone({
                        body: req.body.append('code', this.authService.code)
                    });

                    req = req.clone({
                        body: req.body.append('challenge', this.authService.challange)
                    });
                }

                return next
                    .handle(req)
                    .pipe(catchError(err => this.errorHandler.handleError(err)));
            })
        );
    }
}
