import { HttpParams } from '@angular/common/http';
import { PaginationInterface, PaginationParam, SearchInterface } from '../interfaces';

export class Pagination implements PaginationInterface {
  constructor(
    public page: number = 1,
    public limit: number = 25,
    public params: PaginationParam[] = []
  ) { }

  public toHttpParams(): HttpParams {
    let params = new HttpParams();

    params = params.append('page', this.page.toString());
    params = params.append('limit', this.limit.toString());

    if (this.params && this.params.length > 0) {
      for (const p of this.params) {
        if (p.value instanceof Array) {
          for (const value of p.value) {
            params = params.append(p.name, value);
          }
        } else {
          params = params.append(p.name, p.value);
        }
      }
    }

    return params;
  }
  public toPaginationParams(): PaginationParam[] {
    const params: PaginationParam[] = [];

    params.push({ name: 'page', value: this.page.toString() });
    params.push({ name: 'limit', value: this.limit.toString() });

    if (this.params && this.params.length > 0) {
      for (const p of this.params) {
        if (p.value instanceof Array) {
          for (const v of p.value) {
            params.push({ name: p.name, value: v });
          }
        } else {
          params.push({ name: p.name, value: p.value });
        }
      }
    }

    return params;
  }
}

export class Search implements SearchInterface {
  constructor(
    public value = '',
    public params: PaginationParam[] = []
  ) { }

  public toHttpParams(): HttpParams {
    let params = new HttpParams();

    params = params.append('search', this.value.toString());

    if (this.params && this.params.length > 0) {
      for (const p of this.params) {
        if (p.value instanceof Array) {
          for (const value of p.value) {
            params = params.append(p.name, value);
          }
        } else {
          params = params.append(p.name, p.value);
        }
      }
    }

    return params;
  }

  public toPaginationParams(): PaginationParam[] {
    const params: PaginationParam[] = [];

    params.push({ name: 'search', value: this.value.toString() });

    if (this.params && this.params.length > 0) {
      for (const p of this.params) {
        if (p.value instanceof Array) {
          for (const v of p.value) {
            params.push({ name: p.name, value: v });
          }
        } else {
          params.push({ name: p.name, value: p.value });
        }
      }
    }

    return params;
  }
}

export class HttpParamsUtils {
  public static getHttpParamsFromPaginationParams(...items: Array<PaginationParam[]>): HttpParams {
    let params = new HttpParams();
    items.forEach((item: PaginationParam[]) => {
      if (item) {
        for (const p of item) {
          params = params.append(p.name, p.value);
        }
      }
    });

    return params;
  }
}
