import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Therapy } from 'src/app/data/models';

export function therapyRequiredValidator(): ValidatorFn {
  const validator: ValidatorFn = (control: AbstractControl) => {

    const value = control.value;
    return value instanceof Therapy || (value === null || value.trim().toLowerCase() === '') ? null : { required: true };
  };

  return validator;
}
