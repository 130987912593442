import { Component } from '@angular/core';

@Component({
  selector: 'app-view-layout',
  templateUrl: 'view-layout.components.html',
  styleUrls: ['./view-layout.components.scss'],
})
export class ViewLayoutComponent {
  constructor() {}
}
