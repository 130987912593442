import { AbstractControl, ValidatorFn } from '@angular/forms';
import { User } from 'src/app/data/models';

export function userRequiredValidator(): ValidatorFn {
  const validator: ValidatorFn = (control: AbstractControl) => {

    const value = control.value;
    return value instanceof User || (value === null || value.trim().toLowerCase() === '') ? null : { required: true };
  };

  return validator;
}
