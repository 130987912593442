import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppAbility, PermissionActions, PermissionSubjects } from '../permissions';
import { AuthGuard } from './auth.guard';

export interface PermissionGuardData {
  subject: PermissionSubjects;
  action: PermissionActions;
}

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private ability: AppAbility
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data = route.data as PermissionGuardData;
    return this.hasPermissions(data, route, state);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data = route.data as PermissionGuardData;
    return this.hasPermissions(data, route, state);
  }
  hasPermissions(data: PermissionGuardData, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authGuard.canActivate(route, state).pipe(
      switchMap((value: boolean | UrlTree) => {
        if (value === true) {
          return of(this.ability.can(data.action, data.subject)).pipe(
            map((hasPermissions: boolean) => {
              if (!hasPermissions) {
                return this.router.parseUrl('/access-denied');
              }
              return hasPermissions;
            })
          );
        } else {
          return of(value);
        }
      })
    );
  }
}
