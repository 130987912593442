<div class="w-100 h-100" *transloco="let t">
  <mat-card class="mat-elevation-z24">
    <mat-card-header>
      <mat-card-title>
        <div [innerHTML]="getEventTooltipTitle(event)" class="w-100"></div>
      </mat-card-title>
      <mat-card-subtitle>{{ getEventTooltipSubtitle(event) }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngTemplateOutlet="isEventPublic(event.meta) ? publicTemplate : privateTemplate; context: event">
      </ng-container>
      <ng-template #publicTemplate>
        <div class="d-flex flex-row">
          <mat-list>
            <div mat-subheader>{{ t('patients.name') | capitalize }}</div>
            <ng-container *ngIf="event.meta.patients.length > 0; else noPatientsTemplate">
              <mat-list-item *ngFor="let patient of event.meta.patients">
                <mat-icon mat-list-icon color="accent">face</mat-icon>
                <div mat-line [ngClass]="{ 'patient-inactive': !isPatientActive(patient) }">
                  {{ patient.getDisplayName() }}
                </div>
                <mat-icon class="ml-2" color="warn" *ngIf="patient.state.state === patientState.NO_SHOW">
                  report_off</mat-icon
                >
                <mat-icon class="ml-2" color="accent" *ngIf="patient.needTranslator === true">translate</mat-icon>
              </mat-list-item>
            </ng-container>
            <ng-template #noPatientsTemplate>
              <mat-list-item>
                <mat-icon mat-list-icon color="accent">face</mat-icon>
                <div mat-line>{{ t('no-one') | capitalize }} ({{ t('free') }})</div>
              </mat-list-item>
            </ng-template>
          </mat-list>
          <mat-list>
            <div mat-subheader>{{ t('users.name') | capitalize }}</div>
            <ng-container *ngIf="event.meta.users.length > 0; else noUsersTemplate">
              <mat-list-item *ngFor="let user of event.meta.users">
                <mat-icon mat-list-icon color="accent">people</mat-icon>
                <div mat-line>{{ user.getDisplayName() }}</div>
              </mat-list-item>
            </ng-container>
            <ng-template #noUsersTemplate>
              <mat-list-item>
                <mat-icon mat-list-icon color="accent">people</mat-icon>
                <div mat-line>{{ t('no-one') | capitalize }} ({{ t('warning') }})</div>
              </mat-list-item>
            </ng-template>
          </mat-list>
        </div>
        <div class="d-flex flex-row">
          <mat-list>
            <div mat-subheader>{{ t('informations') | capitalize }}</div>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">list_alt</mat-icon>
              <div mat-line>{{ event.meta.goal ? event.meta.goal.name : '-' }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">spa</mat-icon>
              <div mat-line>{{ event.meta.therapy ? event.meta.therapy.name : '-' }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">apartment</mat-icon>
              <div mat-line>{{ event.meta.office ? event.meta.office.name : '-' }}</div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">meeting_room</mat-icon>
              <div mat-line>{{ event.meta.room ? event.meta.room.name : '-' }}</div>
            </mat-list-item>
          </mat-list>
          <mat-list>
            <div mat-subheader>{{ t('complementary') | capitalize }}</div>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">location_on</mat-icon>
              <div mat-line class="text-fields text-truncate text-wrap">
                {{ event.meta.address ? event.meta.address : '-' }}
              </div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">sms</mat-icon>
              <div mat-line class="text-fields text-truncate text-wrap">
                {{ event.meta.comment ? event.meta.comment : '-' }}
              </div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">description</mat-icon>
              <div mat-line class="text-fields text-truncate text-wrap">
                {{ event.meta.description ? event.meta.description : '-' }}
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </ng-template>
      <ng-template #privateTemplate>
        <mat-list>
          <div mat-subheader>{{ t('users.name') | capitalize }}</div>
          <ng-container *ngIf="event.meta.users.length > 0; else noUsersTemplate">
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">people</mat-icon>
              <div mat-line>
                <span>{{ event.meta.users[0].getDisplayName() }}</span>
                <span *ngIf="event.meta.users.length > 1">(+{{ event.meta.users.length - 1 }})</span>
              </div>
            </mat-list-item>
          </ng-container>
          <ng-template #noUsersTemplate>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">people</mat-icon>
              <div mat-line>{{ t('no-one') | capitalize }} ({{ t('warning') }})</div>
            </mat-list-item>
          </ng-template>
        </mat-list>
        <mat-list>
          <div mat-subheader>{{ t('complementary') | capitalize }}</div>
          <mat-list-item>
            <mat-icon mat-list-icon color="accent">apartment</mat-icon>
            <div mat-line>{{ event.meta.office ? event.meta.office.name : '-' }}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon mat-list-icon color="accent">meeting_room</mat-icon>
            <div mat-line>{{ event.meta.room ? event.meta.room.name : '-' }}</div>
          </mat-list-item>
          <ng-container *ngIf="canVisualizePrivateData(event.meta); else privateDataTemplate">
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">location_on</mat-icon>
              <div mat-line class="text-fields text-truncate text-wrap">
                {{ event.meta.address ? event.meta.address : '-' }}
              </div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">sms</mat-icon>
              <div mat-line class="text-fields text-truncate text-wrap">
                {{ event.meta.comment ? event.meta.comment : '-' }}
              </div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">description</mat-icon>
              <div mat-line class="text-fields text-truncate text-wrap">
                {{ event.meta.description ? event.meta.description : '-' }}
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
        <ng-template #privateDataTemplate>
          <mat-list>
            <mat-list-item>
              <mat-icon mat-list-icon color="accent">lock</mat-icon>
              <div mat-line>{{ t('is-private-data') | capitalize }}</div>
            </mat-list-item>
          </mat-list>
        </ng-template>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
