import { FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function dateStartBeforeEndValidator(start: string = 'startDate', end: string = 'endDate'): ValidatorFn {
  const validator: ValidatorFn = (group: FormGroup) => {
    const startTime = moment(group.get(start).value);
    const endTime = moment(group.get(end).value);

    return startTime.isBefore(endTime) ? null : { dateStartBeforeEnd: true };
  };

  return validator;
}
