<ng-container *transloco="let t">
  <h1 mat-dialog-title>{{ t(title) | capitalize }}</h1>
  <div mat-dialog-content>
    <p [innerHtml]="t(message) | capitalize"></p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="cancel()">{{ t('cancel') | capitalize }}</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>{{ t('confirm') | capitalize }}</button>
  </div>
</ng-container>
