import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private authService: AuthService
  ) { }

  resetDataServices() {
    this.authService.resetData();
  }
}
