import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter, ExternalEntityInterface, HttpEntityInterface } from '../interfaces';
import { PatientAppointmentState } from './appointment-state';

export enum PatientGender {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export class Patient implements HttpEntityInterface, ExternalEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public firstName: string,
    public lastName: string,
    public gender: PatientGender,
    public needTranslator: boolean,

    public createdAt: moment.Moment,
    public modifiedAt: moment.Moment,

    public active: boolean,

    public birthdate?: moment.Moment,
    public email?: string,
    public phone?: string,
    public mobile?: string,
    public comment?: string,

    public externalId?: number,
    public state?: PatientAppointmentState
  ) {}

  public getDisplayName(): string {
    return `${this.lastName && this.lastName.toUpperCase()} ${this.firstName}`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PatientAdapter implements Adapter<Patient> {
  constructor() {}

  public adaptToObject(item: any): Patient {
    const patient = new Patient(
      item.id,
      item.url,
      item.first_name,
      item.last_name,
      item.gender as PatientGender,
      item.contact_translator,
      moment.tz(item.created_at, 'UTC'),
      moment.tz(item.modified_at, 'UTC'),
      item.active
    );

    patient.birthdate = item && item.birthdate && moment.tz(item.birthdate, 'UTC');
    patient.email = item && item.email;
    patient.phone = item && item.phone;
    patient.mobile = item && item.mobile;
    patient.comment = item && item.comment;

    return patient;
  }
  public adaptToRequest(item: Patient): any {
    const object = {
      firstName: item.firstName,
    };

    if (item.id) {
      object[`id`] = item.id;
    }

    return object;
  }
}
