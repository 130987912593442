import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission } from 'src/app/data/interfaces';
import { Group, User, UserAdapter } from 'src/app/data/models';
import { AppAbility } from '../permissions';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly $user: BehaviorSubject<User> = new BehaviorSubject(undefined);

  constructor(
    protected oauthService: OAuthService,
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private adapter: UserAdapter,
    private ability: AppAbility,
  ) { }

  public isAuthenticated(): Observable<boolean> {
    return of(this.oauthService.hasValidAccessToken());
  }

  public getUser(refresh?: boolean): Observable<User> {
    if (refresh || !this.$user.getValue()) {
      const url = this.settingsService.getSettings().baseUrl + `profile/`;
      return this.httpClient.get(url).pipe(
        map((result: any) => {
          const user = this.adapter.adaptToObject(result);
          this.updatePermissions(user);

          this.$user.next(user);

          return this.$user.getValue();
        })
      );
    } else {
      return this.$user.asObservable();
    }
  }

  public resetData(): void {
    this.$user.next(undefined);
  }

  private updatePermissions(user: User): void {
    let rules: any[] = [];

    user.groups.forEach((group: Group) => {
      const permissions = group.permissions.map((p: Permission) => {
        const values = p.codename.split('_');
        return { action: values[0], subject: values[1] };
      });

      rules = rules.concat(permissions);
    });

    const r = rules.reduce((newArray, item) => {
      return newArray.some(c => c.action === item.action && c.subject === item.subject) ?
        newArray : [...newArray, item];
    }, []);

    this.ability.update(r);
  }

  public get challange(): string {
    return localStorage.getItem('2fa_challange');
  }

  public set challange(challange: string) {
    localStorage.setItem('2fa_challange', challange);
  }

  public get code(): string {
    return localStorage.getItem('2fa_code');
  }

  public set code(code: string) {
    localStorage.setItem('2fa_code', code);
  }
}
