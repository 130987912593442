import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { TranslocoService } from '@ngneat/transloco';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Boot } from './core/boot';
import { CoreModule } from './core/core.module';
import { AppAbility } from './core/permissions';
import { SettingsService } from './core/services/settings.service';
import { DynamicLocaleId } from './data/models';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { NoPermissionsLayoutComponent } from './layout/nopermissions-layout/nopermissions-layout.components';
import { NotFoundLayoutComponent } from './layout/notfound-layout/notfound-layout.components';
import { ViewLayoutComponent } from './layout/view-layout/view-layout.components';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localeDe, 'de-CH', localeDeExtra);
registerLocaleData(localeFr, 'fr-CH', localeFrExtra);
registerLocaleData(localeEn, 'en-US', localeEnExtra);

Sentry.init({
  dsn: environment.sentry.url,
  environment: environment.sentry.environment,
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     tracingOrigins: ['localhost', 'https://yourserver.io/api'],
  //     routingInstrumentation: Sentry.routingInstrumentation,
  //   }),
  // ],
  // tracesSampleRate: 1.0,
  ignoreErrors: [/(401 OK)$/]
});

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    NotFoundLayoutComponent,
    NoPermissionsLayoutComponent,
    ViewLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AbilityModule,

    // i18n
    HttpClientModule,
    TranslocoRootModule,

    // core & shared
    CoreModule,
    SharedModule,

    // app
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: Boot.bootSettings,
      deps: [SettingsService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslocoService]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
    provide: Sentry.TraceService,
    deps: [Router],
    useValue: undefined
},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: AppAbility, useValue: new AppAbility() },
    { provide: PureAbility, useExisting: AppAbility }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
