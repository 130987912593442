import { DatePipe } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { Appointment, AppointmentVisibility, Patient, PatientAppointmentStateEnum, User } from 'src/app/data/models';
import { BusinessService } from 'src/app/data/services';
import { CapitalizePipe } from '../../pipes';

@Component({
  selector: 'app-calendar-tooltip',
  templateUrl: './calendar-tooltip.component.html',
  styleUrls: ['./calendar-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarTooltipComponent implements OnDestroy {
  @Input() event: CalendarEvent<Appointment>;
  @Input() user: User;

  private subscriber = new Subject();

  public patientState = PatientAppointmentStateEnum;

  constructor(
    private businessService: BusinessService,
    private translocoService: TranslocoService,
    private capitalizePipe: CapitalizePipe,
    @Inject(LOCALE_ID) public locales?: string
  ) {}

  ngOnDestroy(): void {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  public getEventTooltipTitle(e: CalendarEvent<Appointment>): string {
    return `
      <div class="row no-gutters w-100 align-items-center">
        <div class="col text-truncate font-weight-bold">${
          e.meta.title && this.businessService.isAppointmentUpdatable(this.user, e.meta)
            ? e.meta.title
            : this.capitalizePipe.transform(this.translocoService.translate('appointment'))
        }</div>
        <div class="col-auto pl-1">
        ${e.meta.flagAction ? '<img src="/assets/images/icons/action.svg" alt="action">' : ''}
        </div>
        <div class="col-auto pl-1">
        ${this.isActionNeededForAppointment(e.meta) ? '<img src="/assets/images/icons/warning.svg" alt="warning">' : ''}
        </div>
        <div class="col-auto pl-1">
        ${e.meta.comment ? '<img src="/assets/images/icons/comment.svg" alt="warning">' : ''}
        </div>
        <div class="col-auto pl-1">
        ${
          e.meta.patients.some((p) => p.needTranslator && p.state.state !== PatientAppointmentStateEnum.CANCELED)
            ? '<img src="/assets/images/icons/translate.svg" alt="warning">'
            : ''
        }
        </div>
        <div class="col-auto pl-1">
        ${
          e.meta.visibility === AppointmentVisibility.PRIVATE
            ? '<img src="/assets/images/icons/lock.svg" alt="warning">'
            : ''
        }
        </div>
      </div>
    `;
  }

  public getEventTooltipSubtitle(e: CalendarEvent<Appointment>): string {
    return `${new DatePipe(this.locales).transform(e.start, 'dd.MM.yyyy', this.locales)} (${new DatePipe(
      this.locales
    ).transform(e.start, 'HH:mm', this.locales)} - ${new DatePipe(this.locales).transform(
      e.end,
      'HH:mm',
      this.locales
    )})`;
  }

  public isEventPublic(a: Appointment): boolean {
    return a.visibility !== AppointmentVisibility.PRIVATE;
  }

  public isPatientActive(patient: Patient): boolean {
    return this.businessService.isPatientConfirmedInAppointment(patient);
  }

  public canVisualizePrivateData(a: Appointment): boolean {
    return this.businessService.isAppointmentUpdatable(this.user, a);
  }

  private isActionNeededForAppointment(a: Appointment): boolean {
    return this.businessService.isActionNeededForAppointment(a);
  }
}
