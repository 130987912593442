import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Adapter, HttpEntityInterface } from '../interfaces';
import { UserAdapter } from './user';

export enum BlockFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export class Block implements HttpEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public startTime: moment.Moment,
    public endTime: moment.Moment,
    public daysInWeek?: number[],
    public nbAppointments?: number,
    public frequency?: BlockFrequency,
    public interval?: number,
    public daysInMonth?: number[],
    public positionsInSet?: number[]
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class BlockAdapter implements Adapter<Block> {
  constructor(private adapter: UserAdapter) {}

  public adaptToObject(item: any): Block {
    const block = new Block(
      item.id,
      item.url,
      moment(item.start_time, [moment.ISO_8601, 'HH:mm']),
      moment(item.end_time, [moment.ISO_8601, 'HH:mm']),
      item.days_in_week
    );

    block.nbAppointments = item && item.nb_appointments;
    block.interval = item && item.interval;
    block.frequency = item && (item.frequency as BlockFrequency);
    block.daysInMonth = item && (item.days_in_month as number[]);
    block.daysInWeek = item && (item.days_in_week as number[]);
    block.positionsInSet = item && (item.positions_in_set as number[]);

    return block;
  }
  public adaptToRequest(item: Block): any {
    const object = {
      start_time: item.startTime.format('HH:mm'),
      end_time: item.endTime.format('HH:mm'),
      days_in_week: item.daysInWeek,
    };

    if (item.id) {
      object[`id`] = item.id;
    }
    if (item.frequency) {
      object[`frequency`] = item.frequency;
    }
    if (item.interval) {
      object[`interval`] = item.interval;
    }
    if (item.daysInWeek) {
      object[`days_in_week`] = item.daysInWeek;
    }
    if (item.daysInMonth) {
      object[`days_in_month`] = item.daysInMonth;
    }
    if (item.positionsInSet) {
      object[`positions_in_set`] = item.positionsInSet;
    }

    return object;
  }
}
