import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter, ExternalEntityInterface, HttpEntityInterface } from '../interfaces';
import { User, UserAdapter } from './user';

export class Office implements HttpEntityInterface, ExternalEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public name: string,
    public location: string,
    public createdAt: moment.Moment,
    public modifiedAt: moment.Moment,

    public externalId?: number,
    public createdBy?: User,
    public modifiedBy?: User,
    public deletedAt?: moment.Moment,
    public deletedBy?: User,

    public active?: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class OfficeAdapter implements Adapter<Office> {
  constructor(private adapter: UserAdapter) {}

  public adaptToObject(item: any): Office {
    const office = new Office(
      item.id,
      item.url,
      item.name,
      item.location,
      moment.tz(item.created_at, 'UTC'),
      moment.tz(item.modified_at, 'UTC')
    );

    office.externalId = item && item.external_id;
    office.deletedAt = item && moment.tz(item.deleted_at, 'UTC');

    if (item && item.created_by) {
      office.createdBy = this.adapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      office.modifiedBy = this.adapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      office.deletedBy = this.adapter.adaptToObject(item.deleted_by);
    }

    if (item && item.active !== null && item.active !== undefined) {
      office.active = item.active;
    }

    return office;
  }
  public adaptToRequest(item: Office): any {
    const object = {
      name: item.name,
      active: item.active,
    };

    if (item.id) {
      object[`id`] = item.id;
    }

    return object;
  }
}
