<ng-container *transloco="let t">
  <form [formGroup]="appointmentForm" class="d-flex flex-column w-100 justify-content-between">
    <div class="section-title">
      <h2>{{ t('informations') | capitalize }}</h2>
      <button mat-icon-button (click)="clearForm()">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    <mat-form-field>
      <mat-label>{{ t('visibility') | capitalize }}</mat-label>
      <mat-select formControlName="visibility">
        <mat-option [value]="-1">{{ t('actual-value') | capitalize }}</mat-option>
        <ng-container *ngFor="let v of appointmentVisibility | stringEnumToArray">
          <mat-option *ngIf="canDisplayVisibility(v.value)" [value]="v.value">
            {{ t('visibilities.' + v.key.toLowerCase()) | capitalize }}</mat-option
          >
        </ng-container>
      </mat-select>
    </mat-form-field>
    <app-therapy-select-bulk
      [control]="appointmentForm.controls.therapy"
      [params]="activeDataParams"
    ></app-therapy-select-bulk>
    <app-office-select-bulk
      [control]="appointmentForm.controls.office"
      [params]="activeDataParams"
    ></app-office-select-bulk>
    <app-room-select-bulk
      [control]="appointmentForm.controls.room"
      [office]="appointmentForm.controls.office.value"
      [params]="activeDataParams"
    >
    </app-room-select-bulk>

    <mat-divider [vertical]="true"></mat-divider>

    <div class="section-title">
      <h2>{{ t('participants') | capitalize }}</h2>
      <button mat-icon-button (click)="clearUsers()">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    <app-users-select-bulk [control]="appointmentForm.controls.users"></app-users-select-bulk>

    <mat-divider [vertical]="true"></mat-divider>

    <div class="section-title">
      <h2>{{ t('complementary') | capitalize }}</h2>
      <button mat-icon-button (click)="clearComplementary()">
        <mat-icon>restore</mat-icon>
      </button>
    </div>
    <mat-form-field>
      <mat-label>{{ t('comment') | capitalize }}</mat-label>
      <textarea matInput cdkTextareaAutosize formControlName="comment"></textarea>
      <mat-error *ngIf="appointmentForm.controls.comment.invalid">
        {{ t('validation.value-required') | capitalize }}
      </mat-error>
    </mat-form-field>
    <mat-label>{{ t('color') | capitalize }}</mat-label>
    <input
      [(colorPicker)]="color"
      [cpPresetColors]="presetColors"
      [cpPosition]="'top'"
      [style.background]="color"
      [cpAlphaChannel]="'disabled'"
    />
    <mat-slide-toggle color="primary" class="py-3" formControlName="withNotification">{{ t('send-notification') | capitalize }}</mat-slide-toggle>
  </form>
  <mat-error *ngIf="appointmentForm.touched && appointmentForm.errors?.oneDimensionRequired">
    {{ t('therapy-blocks.validation.must-select-one-dimension') | capitalize }}
  </mat-error>

  <h2 class="mt-3">{{ t('actions') | capitalize }}</h2>
  <div class="section-title">
    <div class="pr-2">
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!appointmentForm.valid || loading">
        <!-- <mat-icon>save</mat-icon> -->
        <span>{{ t('save') | capitalize }}</span>
      </button>
    </div>
    <div class="px-2">
      <button mat-raised-button color="accent" (click)="clearAll()">
        <!-- <mat-icon>restore</mat-icon> -->
        <span>{{ t('reset') | capitalize }}</span>
      </button>
    </div>
    <div class="px-2">
      <button mat-raised-button color="warn" (click)="delete()">
        <!-- <mat-icon>trash</mat-icon> -->
        <span>{{ t('delete') | capitalize }}</span>
      </button>
    </div>
  </div>
</ng-container>
