import { Injectable } from '@angular/core';
import { SettingsService } from 'src/app/core/services';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class ExportsService {
  constructor(
    private settingsService: SettingsService
  ) { }

  public getPersonalCalendarUrl(user: User): string {
    return this.settingsService.getSettings().baseUrl + `exports/${user.id}/feed.ics`;
  }
}
