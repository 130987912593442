import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthService, CommonService, SettingsService } from './core/services';
import { SettingsInterface } from './data/interfaces';
import { User } from './data/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'calyce-frontend';
  public loading: boolean;

  public logoUrl: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private oauthService: OAuthService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private commonService: CommonService,
    private translocoService: TranslocoService,
    private titleService: Title,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.loading = true;
  }

  ngOnInit() {
    const settings = this.settingsService.getSettings();

    this.logoUrl = settings.logoUrl;

    this.document.documentElement.lang = settings.locale;

    this.translocoService.setActiveLang(settings.locale);
    this.translocoService
      .selectTranslate('app-title')
      .pipe(take(1))
      .subscribe((value) => this.titleService.setTitle(value));

    // OAuth configuration
    this.configureOAuthService(this.settingsService.getSettings());
    this.tryLogin();
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loading = false;
      }
    });
  }

  private configureOAuthService(config: SettingsInterface) {
    this.oauthService.configure(config.oauth);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService
      .tryLogin({
        onTokenReceived: (context) => {},
      })
      .then((hasValidToken) => {
        if (hasValidToken) {
          this.tryLogin();
        }
      });

    this.oauthService.events.subscribe((e: OAuthEvent) => {
      switch (e.type) {
        case 'token_expires':
          this.oauthService.refreshToken().then(() => {
            console.log('Access token refreshed');
          });
          break;
        case 'session_error':
        case 'logout':
        case 'token_validation_error':
        case 'token_refresh_error':
          this.dialog.closeAll();
          this.commonService.resetDataServices();
          this.router.navigate(['/']);
          break;
      }
    });
  }
  private tryLogin(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.authService
        .getUser()
        .pipe(catchError(() => of(false)))
        .subscribe((value: User | boolean) => {
          if (value === false) {
            this.oauthService.logOut();
            this.router.navigate(['/auth/login']);
          }
        });
    }
  }
}
