<mat-drawer-container class="main-container" *transloco="let t; read: 'main-navigation'">
  <mat-drawer #drawer mode="side" opened>
    <div>
      <ng-container *ngIf="displayTestBanner">
        <div class="bandeau">
          Test
        </div>
      </ng-container>
      <img
        [src]="logoUrl"
        onerror="this.src = 'assets/images/logo.svg'"
        alt="logo"
        srcset=""
        style="width: 14rem; height: auto"
        class="d-block mx-3 mt-4"
      />
    </div>
    <h1 class="m-3 mt-5">{{ t('planning') | titlecase }}</h1>
    <mat-divider></mat-divider>
    <mat-nav-list class="mat-typography main-navigation-selection">
      <a
        mat-list-item
        *ngIf="'view' | able: 'appointment'"
        [routerLink]="['/planning', 'search']"
        routerLinkActive="active"
        class="text-center"
      >
        <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">search </mat-icon>
        <span matLine class="text-center nav-main-text">{{ t('search') | titlecase }}</span>
      </a>
      <a
        mat-list-item
        *ngIf="'view' | able: 'appointment'"
        [routerLink]="['/planning', 'scheduler']"
        routerLinkActive="active"
        class="text-center"
      >
        <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon"
          >view_column
        </mat-icon>
        <span matLine class="text-center nav-main-text">{{ t('schedulers') | titlecase }}</span>
      </a>
      <a
        mat-list-item
        *ngIf="'view' | able: 'appointment'"
        [routerLink]="['/planning', 'calendar']"
        routerLinkActive="active"
        class="text-center"
      >
        <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">today </mat-icon>
        <div matLine class="text-center nav-main-text">{{ t('calendars') | titlecase }}</div>
      </a>
      <a
        mat-list-item
        *ngIf="'view' | able: 'therapyblock'"
        [routerLink]="['/admin', 'blocks']"
        routerLinkActive="active"
        class="text-center"
      >
        <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon"
          >date_range
        </mat-icon>
        <div matLine class="text-center nav-main-text">{{ t('blocs') | titlecase }}</div>
      </a>
    </mat-nav-list>
    <ng-container
      *ngIf="
        ('view' | able: 'goal') ||
        ('view' | able: 'office') ||
        ('view' | able: 'room') ||
        ('view' | able: 'therapy') ||
        ('view' | able: 'user') ||
        ('view' | able: 'patient')
      "
    >
      <h1 class="m-3">{{ t('resources') | titlecase }}</h1>
      <mat-divider></mat-divider>
      <mat-nav-list class="mat-typography main-navigation-selection">
        <a
          mat-list-item
          *ngIf="'view' | able: 'goal'"
          [routerLink]="['/admin', 'goals']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon"
            >list_alt
          </mat-icon>
          <span matLine class="text-center nav-main-text">{{ t('goals') | titlecase }}</span>
        </a>
        <a
          mat-list-item
          *ngIf="'view' | able: 'office'"
          [routerLink]="['/admin', 'offices']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon"
            >apartment
          </mat-icon>
          <span matLine class="text-center nav-main-text">{{ t('offices') | titlecase }}</span>
        </a>
        <a
          mat-list-item
          *ngIf="'view' | able: 'room'"
          [routerLink]="['/admin', 'rooms']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon"
            >meeting_room
          </mat-icon>
          <span matLine class="text-center nav-main-text">{{ t('rooms') | titlecase }}</span>
        </a>
        <a
          mat-list-item
          *ngIf="'view' | able: 'therapy'"
          [routerLink]="['/admin', 'therapies']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">spa </mat-icon>
          <div matLine class="text-center nav-main-text">{{ t('therapies') | titlecase }}</div>
        </a>
        <a
          mat-list-item
          *ngIf="'view' | able: 'patient'"
          [routerLink]="['/admin', 'patients']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">face </mat-icon>
          <span matLine class="text-center nav-main-text">{{ t('patients') | titlecase }}</span>
        </a>
      </mat-nav-list>
      <h1 class="m-3">{{ t('administration') | titlecase }}</h1>
      <mat-divider></mat-divider>
      <mat-nav-list class="mat-typography main-navigation-selection">
        <a
          mat-list-item
          *ngIf="'view' | able: 'user'"
          [routerLink]="['/admin', 'users']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">person </mat-icon>
          <span matLine class="text-center nav-main-text">{{ t('collaborators') | titlecase }}</span>
        </a>
        <a
          mat-list-item
          *ngIf="'view' | able: 'businessgroup'"
          [routerLink]="['/admin', 'groups']"
          routerLinkActive="active"
          class="text-center"
        >
          <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">people </mat-icon>
          <span matLine class="text-center nav-main-text">{{ t('businessgroups') | titlecase }}</span>
        </a>
      </mat-nav-list>
    </ng-container>
    <h1 class="m-3">{{ t('actions') | titlecase }}</h1>
    <mat-divider></mat-divider>
    <mat-nav-list class="mat-typography main-navigation-selection">
      <a mat-list-item (click)="logout()" class="text-center">
        <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon"
          >power_settings_new
        </mat-icon>
        <span matLine class="text-center nav-main-text">{{ t('logout') | titlecase }}</span> </a
      ><!--
      <a mat-list-item class="text-center mt-2">
        <mat-icon matLine class="main-icon material-icons-outlined align-self-center nav-main-icon">translate
        </mat-icon>
        <mat-form-field style="padding-left: 1.5rem;">
          <mat-label>{{ t('language') | titlecase }}</mat-label>
          <mat-select [formControl]="languageControl">
            <mat-option *ngFor="let lang of availableLanguages" [value]="lang">{{ t(lang) | titlecase }}</mat-option>
          </mat-select>
        </mat-form-field>
      </a> -->
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content class="d-flex flex-row">
    <div class="d-flex align-items-center">
      <button mat-raised-button color="primary" class="drawer-button" (click)="drawer.toggle()">
        <ng-container *ngIf="drawer.opened; else close">
          <mat-icon>chevron_left</mat-icon>
        </ng-container>
        <ng-template #close>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </button>
    </div>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
