import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/services';
import { LogoutDialogComponent } from 'src/app/modules/auth/logout/logout.dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  displayTestBanner: boolean = false;
  private unsubscribe: Subject<void> = new Subject();

  public availableLanguages: AvailableLangs;
  public languageControl: FormControl;

  public logoUrl: string;

  constructor(
    private translocoService: TranslocoService,
    private dialog: MatDialog,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.logoUrl = this.settingsService.getSettings().logoUrl;

    this.displayTestBanner = this.settingsService.getSettings().displayTestBanner;

    this.availableLanguages = this.translocoService.getAvailableLangs();

    this.languageControl = new FormControl();
    this.languageControl.setValue(this.getLanguage());

    this.languageControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value: any) => {
      this.setLanguage(value);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  public setLanguage(language: string): void {
    this.translocoService.setActiveLang(language);
  }

  public getLanguage(): string {
    return this.translocoService.getActiveLang();
  }
  public logout(): void {
    this.dialog.open(LogoutDialogComponent);
  }
}
