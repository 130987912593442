import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SettingsInterface } from 'src/app/data/interfaces';
import { SettingsService } from '../services';

@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
    private settingsService: SettingsService
  ) { }

  protected checkUrl(allowedUrls: string[], url: string): boolean {
    const found = allowedUrls.find(u =>
      url.startsWith(u)
    );
    return !!found;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    if (!this.settingsService.getSettings()) {
      return next.handle(req);
    }

    return from(this.settingsService.downloadSettings()).pipe(
      switchMap((settings: SettingsInterface) => {
        const moduleConfig = {
          resourceServer: {
            allowedUrls: [settings.baseUrl],
            sendAccessToken: true
          }
        } as OAuthModuleConfig;

        if (!moduleConfig) {
          return next.handle(req);
        }
        if (!moduleConfig.resourceServer) {
          return next.handle(req);
        }
        if (!moduleConfig.resourceServer.allowedUrls) {
          return next.handle(req);
        }
        if (!this.checkUrl(moduleConfig.resourceServer.allowedUrls, url)) {
          return next.handle(req);
        }

        const sendAccessToken = moduleConfig.resourceServer.sendAccessToken;

        if (sendAccessToken && this.authStorage.getItem('access_token')) {
          const token = this.authStorage.getItem('access_token');
          const header = 'Bearer ' + token;

          const headers = req.headers.set('Authorization', header);

          req = req.clone({ headers });
        }

        return next
          .handle(req)
          .pipe(catchError(err => this.errorHandler.handleError(err)));
      })
    );
  }
}
