import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Adapter, ExternalEntityInterface, HttpEntityInterface } from '../interfaces';
import { User, UserAdapter } from './user';

export class Goal implements HttpEntityInterface, ExternalEntityInterface {
  constructor(
    public id: number,
    public url: string,
    public name: string,
    public active: boolean,
    public defaultDuration: moment.Moment,
    public createdAt: moment.Moment,
    public modifiedAt: moment.Moment,

    public externalId?: number,
    public reminderHoursToPlan?: number,
    public reminderHoursPlanned?: number,
    public createdBy?: User,
    public modifiedBy?: User,
    public deletedAt?: moment.Moment,
    public deletedBy?: User
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class GoalAdapter implements Adapter<Goal> {
  constructor(private adapter: UserAdapter) {}

  public adaptToObject(item: any): Goal {
    const goal = new Goal(
      item.id,
      item.url,
      item.name,
      item.active,
      moment(item.default_duration, [moment.ISO_8601, 'HH:mm']),
      moment.tz(item.created_at, 'UTC'),
      moment.tz(item.modified_at, 'UTC')
    );

    goal.externalId = item && item.external_id;
    goal.reminderHoursToPlan = item && item.reminder_hours_to_plan;
    goal.reminderHoursPlanned = item && item.reminder_hours_planned;

    goal.deletedAt = item && moment.tz(item.deleted_at, 'UTC');

    if (item && item.created_by) {
      goal.createdBy = this.adapter.adaptToObject(item.created_by);
    }

    if (item && item.modified_by) {
      goal.modifiedBy = this.adapter.adaptToObject(item.modified_by);
    }

    if (item && item.deleted_by) {
      goal.deletedBy = this.adapter.adaptToObject(item.deleted_by);
    }

    return goal;
  }
  public adaptToRequest(item: Goal): any {
    const object = {
      name: item.name,
      active: item.active,
      default_duration: item.defaultDuration.format('HH:mm:ss'),
    };

    if (item.id) {
      object[`id`] = item.id;
    }

    return object;
  }
}
