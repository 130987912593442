import { Ability, AbilityClass } from '@casl/ability';

export type PermissionActions = 'add' | 'view' | 'change' | 'delete' | 'manage';
export type PermissionSubjects =
  | 'therapy'
  | 'user'
  | 'office'
  | 'room'
  | 'patient'
  | 'therapyblock'
  | 'block'
  | 'appointment'
  | 'goal'
  | 'synchronization'
  | 'businessgroup'
  | 'all';

export type AppAbility = Ability<[PermissionActions, PermissionSubjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
