<ng-container *transloco="let t; read: 'logout'">
  <h1 mat-dialog-title>{{t('logout') | capitalize }}</h1>
  <div mat-dialog-content>
    <p>{{t('do_want_logout') | capitalize }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{t('no') | capitalize }}</button>
    <button mat-button (click)="logout()" cdkFocusInitial>{{t('logout') | capitalize }}</button>
  </div>
</ng-container>
